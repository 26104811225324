import React from "react"
import { Accordion, Badge, Divider, Flex, Text } from "@mantine/core"
import useTokenBalance from "../../../../../../api/tokens/useTokenBalance"
import { Tokens } from "../../../../../../types"

const TransactionHistoryAction = () => {
  const { transactions } = useTokenBalance()

  return (
    <Accordion w={"100%"}>
      <Accordion.Item value="history">
        <Accordion.Control>
          <Text>History</Text>
        </Accordion.Control>
        <Accordion.Panel>
          <TransactionList transactions={transactions} />
        </Accordion.Panel>
      </Accordion.Item>
    </Accordion>
  )
}

const TransactionList = ({ transactions }: { transactions: Tokens[] }) => {
  return (
    <Flex
      w={"100%"}
      sx={{
        overflow: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        minHeight: "300px",
        maxHeight: "300px",
      }}
    >
      {transactions.sort(sortByCreationDate).map((transaction, index) => (
        <li style={{ listStyleType: "none", width: "100%" }} key={index}>
          <Flex
            key={index}
            w={"100%"}
            align={"center"}
            justify={"space-between"}
            mah={300}
            p={8}
          >
            <Text>{transaction.amount}</Text>
            <Badge
              color={isDeduction(transaction) ? "gray" : "green"}
              variant={"light"}
            >
              {findTransactionTypeString(transaction)}
            </Badge>
          </Flex>
          <Divider />
        </li>
      ))}
      {transactions.length === 0 && <EmptyState />}
    </Flex>
  )
}

const sortByCreationDate = (a: Tokens, b: Tokens) => {
  return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
}

const EmptyState = () => {
  return (
    <Flex
      w={"100%"}
      sx={{
        overflow: "auto",
        justifyContent: "flex-end",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Text m="auto">No transactions yet...</Text>
    </Flex>
  )
}

export const deductionTypes = {
  GENERATE_NAME: "GENERATE_NAME",
  GENERATE_STORY: "GENERATE_STORY",
  GENERATE_APPERANCE: "GENERATE_APPERANCE",
  GENERATE_PERSONALITY: "GENERATE_PERSONALITY",
  SUMMON_CHARACTER: "SUMMON_CHARACTER",
  GENERATE_QUICK_CHARACTER: "GENERATE_QUICK_CHARACTER",
}

export const grantTypes = {
  PURCHASED: "PURCHASED",
  REFUND: "REFUND",
  SIGN_UP: "SIGN_UP",
  FREE_TOKENS: "FREE_TOKENS",
}

const findTransactionTypeString = (transaction: Tokens) => {
  switch (transaction.type) {
    case deductionTypes.GENERATE_NAME:
      return "Generated Name"
    case deductionTypes.GENERATE_STORY:
      return "Generated Story"
    case deductionTypes.GENERATE_APPERANCE:
      return "Generated Apperance"
    case deductionTypes.GENERATE_PERSONALITY:
      return "Generated Personality"
    case grantTypes.PURCHASED:
      return "Purchased Tokens"
    case grantTypes.REFUND:
      return "Refunded Tokens"
    case grantTypes.SIGN_UP:
      return "Sign Up Gift"
    case deductionTypes.SUMMON_CHARACTER:
      return "Summoned Character"
    case deductionTypes.GENERATE_QUICK_CHARACTER:
      return "Summoned Character"
    case grantTypes.FREE_TOKENS:
      return "Free Tokens"
    default:
      return "Unknown"
  }
}

const isDeduction = (transaction: Tokens) => transaction.amount < 0

export default TransactionHistoryAction
