import { Carousel } from "@mantine/carousel"
import React, { useContext } from "react"
import CharacterCard, {
  CHARACTER_CARD_DIMENSION_WIDTH,
} from "../../../ui/characterCard/CharacterCard"
import { PublishedCharacterBadges } from "./components/Badges"
import { ExplorePageContext } from "./context"
import CharacterCardLoading from "../../../ui/characterCard/CharacterCardLoading"

const PopularCharacters = () => {
  const {
    popular: { characters, loadMoreIfNecessary, loading },
  } = useContext(ExplorePageContext)

  return (
    <Carousel
      onSlideChange={loadMoreIfNecessary}
      align={"start"}
      slideGap={"xs"}
      p={16}
      slideSize={CHARACTER_CARD_DIMENSION_WIDTH}
    >
      {loading &&
        Array.from({ length: 6 }).map(() => (
          <Carousel.Slide key={Math.random().toString()}>
            <CharacterCardLoading />
          </Carousel.Slide>
        ))}
      {characters.map((character) => (
        <Carousel.Slide key={character.id}>
          <CharacterCard
            top={<PublishedCharacterBadges character={character} />}
            character={character}
          />
        </Carousel.Slide>
      ))}
    </Carousel>
  )
}

export default PopularCharacters
