import React, { useCallback, useEffect, useState } from "react"
import { MantineProvider, MantineThemeOverride } from "@mantine/core"
import theme from "./ui/theme"
import { UIProvider } from "./ui/context"
import initializeLocalization from "./localization"
import { RouterProvider } from "react-router-dom"
import router from "./app/router"
import { auth } from "./firebase"
import { persistUser } from "./app/authentication/components/utils"
import { User, signInAnonymously } from "firebase/auth"
import { UserContext, persistedUser } from "./global/user"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"

initializeLocalization()

const Root = () => {
  const [user, setUser] = useState<User | undefined>(persistedUser)

  const updateUserState = useCallback((user: User | null) => {
    if (user) {
      persistUser(user)
      setUser(user)
    }
  }, [])

  auth.onAuthStateChanged(updateUserState)

  useEffect(() => {
    if (!user && !localStorage.getItem("signedOutUser")) {
      signInAnonymously(auth)
    }
  }, [])

  return (
    <QueryClientProvider client={new QueryClient()}>
      <UserContext.Provider value={user}>
        <UIProvider>
          <MantineProvider
            theme={theme as unknown as MantineThemeOverride}
            withGlobalStyles
            withNormalizeCSS
          >
            <RouterProvider router={router} />
          </MantineProvider>
        </UIProvider>
      </UserContext.Provider>
    </QueryClientProvider>
  )
}

export default Root
