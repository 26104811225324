import React from "react"
import { Image } from "@mantine/core"

const PlaceholderImage = () => {
  return (
    <Image
      sx={{
        filter: "grayscale(100%) opacity(25%) blur(6px)",
        margin: "auto",
      }}
      src={
        "https://firebasestorage.googleapis.com/v0/b/danion-development.appspot.com/o/general%2Fthorngrim.avif?alt=media&token=575566a1-5294-4e18-ae11-313c0cff621b"
      }
      alt="placeholder-image"
    />
  )
}

export default PlaceholderImage
