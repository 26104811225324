import { User } from "firebase/auth"
import { httpsCallable } from "firebase/functions"
import { functions } from "../../firebase"
import endpoints from "../endpoints"

const finalizeConvertedUser = async (user: User) => {
  await httpsCallable(
    functions,
    endpoints.FINALIZE_CONVERTED_USER
  )({ userId: user.uid })
}

export default finalizeConvertedUser
