import React from "react"
// import createCharacter from "../../../../api/character/editorCharacter"
import { CharacterCreateSession } from "../../../../types"
import { useLoaderData } from "react-router-dom"
// import { functions } from "../../../../firebase"
// import { notifications } from "@mantine/notifications"
import CharacterEditor from "./CharacterEditor"

const CreateCharacter = () => {
  // const navigate = useNavigate()

  const { session } = useLoaderData() as { session: CharacterCreateSession }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const onSubmit = async (data: CharacterCreateSession) => {
  //   try {
  //     // await createCharacter(functions)(data)
  //     navigate("/")
  //   } catch (error) {
  //     notifications.show({
  //       title: "Error",
  //       message: "Something went wrong while saving your character",
  //       color: "red",
  //     })
  //   }
  // }

  return <CharacterEditor session={session} />
}

export default CreateCharacter
