import React from "react"
import styled from "styled-components"
import { Button, Flex } from "@mantine/core"
import { useFormContext } from "react-hook-form"

type Properties = {
  name: string
  setActive: (active: boolean) => void
  suggestions: string[]
}

const Suggestions = ({ name, suggestions, setActive }: Properties) => {
  const { setValue } = useFormContext()
  return (
    <div style={{ overflow: "hidden", maxWidth: "calc(100vw - 32px)" }}>
      <Container gap={8} m={8}>
        {suggestions.map((example, index) => (
          <Button
            key={index}
            w={"75vw"}
            variant="light"
            onClick={() => {
              setValue(`${name}.prompt`, example)
              setActive(true)
            }}
          >
            {example}
          </Button>
        ))}
      </Container>
    </div>
  )
}

const Container = styled(Flex)`
  margin: 8px 0;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`

export default Suggestions
