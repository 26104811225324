import React, { useContext, useEffect } from "react"
import { Character, Message } from "../../../../../../types"
import {
  Avatar,
  Button,
  Card,
  Flex,
  Loader,
  Text,
  TextInput,
} from "@mantine/core"
import ai from "../../../../../../api/ai"
import { FormProvider, useForm, useFormContext } from "react-hook-form"
import { UserContext } from "../../../../../../global/user"

const Conversation = ({ character }: { character: Character }) => {
  const [loading, setLoading] = React.useState(false)
  const [conversation, setConversation] = React.useState<Message[]>([])
  const user = useContext(UserContext)

  useEffect(() => {
    const fetchConversation = async () => {
      setLoading(true)
      const initialConversation = await ai.getConversation(character)
      setConversation(initialConversation)
      setLoading(false)
    }
    if (character.id && user) fetchConversation()
  }, [character.id, user])

  const methods = useForm<{ reply: string }>()

  const onSubmit = async ({ reply }: { reply: string }) => {
    const replies = await ai.replyToConversation(character, reply)
    setConversation([...replies.reverse(), ...conversation])
    methods.reset()
  }

  useEffect(() => {
    const messages = document.getElementById("messages")
    messages?.scrollTo(0, messages.scrollHeight)
  }, [conversation])

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Flex
          mt={8}
          w={"100%"}
          h={"100%"}
          mih={"380px"}
          mah={"380px"}
          direction="column"
          justify={"space-between"}
          gap={8}
          style={{ height: "100%", overflow: "scroll" }}
          p={8}
        >
          {!loading ? (
            <Messages character={character} conversation={conversation} />
          ) : (
            <Flex w={"100%"} h={"380px"} align={"center"} justify={"center"}>
              <Loader size={24} />
            </Flex>
          )}
          <MessageInput />
        </Flex>
      </form>
    </FormProvider>
  )
}

const Messages = ({
  character,
  conversation,
}: {
  character: Character
  conversation: Message[]
}) => {
  return (
    <Flex
      id="messages"
      direction={"column"}
      gap={8}
      style={{
        height: "100%",
        overflow: "auto",
      }}
    >
      {conversation
        .map((message, index) => (
          <MessageSection key={index} character={character} message={message} />
        ))
        .reverse()}
    </Flex>
  )
}

const MessageInput = () => {
  const { formState, watch, register } = useFormContext()
  return (
    <Flex gap={8} align={"center"}>
      <TextInput
        disabled={formState.isSubmitting}
        w={"100%"}
        placeholder="Type a reply"
        {...register("reply")}
      />
      <Button
        disabled={!watch("reply")}
        loading={formState.isSubmitting}
        type="submit"
        size="xs"
        variant="outline"
      >
        Ask
      </Button>
    </Flex>
  )
}

const MessageSection = ({
  character,
  message,
}: {
  character: Character
  message: Message
}) => {
  return ["greeting", "character"].includes(message.type) ? (
    <div>
      <Card>
        <Flex gap={16}>
          <Avatar
            size={24}
            src={character.content?.appearance?.image?.url}
            radius={"lg"}
          />
          <Text>{message.content}</Text>
        </Flex>
      </Card>
    </div>
  ) : (
    <div>
      <Card style={{ textAlign: "right" }}>
        <Text>{message.content}</Text>
      </Card>
    </div>
  )
}

export default Conversation
