import endpoints from "../endpoints"
import { httpsCallable, Functions } from "firebase/functions"
import { CharacterCreateSession } from "../../types"

type Properties = {
  session: CharacterCreateSession
}

const generateStory =
  (functions: Functions) =>
  async ({ session }: Properties): Promise<string> => {
    try {
      const { data: story } = await httpsCallable<Properties, string>(
        functions,
        endpoints.GENERATE_STORY
      )({ session })

      return story
    } catch (error) {
      console.error(error)
      return "No story generated"
    }
  }

export default generateStory
