import React, { ReactElement } from "react"
import { Button, Card, Text } from "@mantine/core"
import styled from "styled-components"
import Block from "./components/Block"
import { useNavigate } from "react-router-dom"

const Title = styled(Text)`
	font-weight: bold;
	text-align: center;
`

const isEmergency = false

const EmergencyBlock = ({
	children,
}: {
	children: ReactElement
}): JSX.Element | null => {
	if (isEmergency) {
		return (
			<div style={{ overflow: "hidden", maxHeight: "80vh" }}>
				<Block>
					<OverWhelmed />
				</Block>
				{children}
			</div>
		)
	}

	return children
}

const OverWhelmed = () => {
	const navigate = useNavigate()
	return (
		<Card>
			<Title>Oh my, we&apos;ve been overwhelmed!</Title>
			<Text m={16} align="center">
				We&apos;re sorry, but we&apos;ve been overwhelmed by the response to our
				launch. We&apos;re working hard to increase our capacaty, but in the
				mean time, you can still scroll through characters!
			</Text>
			<Button onClick={() => navigate("/")} fullWidth variant="outline">
				Go explore!
			</Button>
		</Card>
	)
}

export default EmergencyBlock
