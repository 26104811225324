import { httpsCallable } from "firebase/functions"
import { functions } from "../../firebase"
import endpoints from "../endpoints"
import { Character, Message } from "../../types"

const replyToConversation = async (character: Character, reply: string) => {
  const { data: conversationAdditions } = await httpsCallable(
    functions,
    endpoints.REPLY_TO_CONVERSATION
  )({ characterId: character.id, reply })

  return conversationAdditions as Message[]
}

export default replyToConversation
