import { useEffect } from "react"
import { notifications } from "@mantine/notifications"
import { useLocation } from "react-router-dom"

const usePaymentResultNotification = () => {
	const location = useLocation()
	const params = new URLSearchParams(location.search)

	const success = params.get("success")
	const canceled = params.get("canceled")

	useEffect(() => {
		if (success) {
			notifications.show({
				title: "Payment success",
				message: "You've been granted 100 more tokens! 🎉",
				color: "green",
			})
			resetParams()
		}
	}, [success])

	useEffect(() => {
		if (canceled) {
			notifications.show({
				title: "Payment canceled",
				message: "You can try again later!",
				color: "orange",
			})
			resetParams()
		}
	}, [canceled])
}

const resetParams = () => {
	const url = new URL(window.location.href)
	url.searchParams.delete("success")
	url.searchParams.delete("canceled")
	window.history.replaceState({}, "", url.toString())
}

export default usePaymentResultNotification
