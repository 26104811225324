import { httpsCallable } from "firebase/functions"
import { functions } from "../../firebase"
import endpoints from "../endpoints"
import { Character, Message } from "../../types"

const getConversation = async (character: Character) => {
  const { data: conversation } = await httpsCallable(
    functions,
    endpoints.GET_CONVERSATION
  )({ characterId: character.id })

  return conversation as Message[]
}

export default getConversation
