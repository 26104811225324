import React, { useContext } from "react"
import { Text, Card, Flex, Divider } from "@mantine/core"
import SendVerificationEmailButton from "./components/SendVerificationEmailButton"
import EmailVerifiedBadge from "./components/EmailVerifiedBadge"
import SignOut from "../../../../shell/components/sideDrawer/components/components/SignOut"
import { UserContext } from "../../../../../global/user"

const Account = () => {
	const user = useContext(UserContext)
	return (
		<Card shadow="sm">
			<Flex gap={8} justify="center" direction={"column"} m={8}>
				<Text size={"xl"}>Account Details</Text>
				<Divider />
				<Card.Section p={8}>
					<Flex justify={"space-between"} direction={"column"} gap={8}>
						<Flex direction={"row"} justify={"space-between"} align={"center"}>
							<Text>Email</Text>
							<EmailVerifiedBadge emailVerified={user?.emailVerified} />
						</Flex>
						<Text size={"xl"}>{user?.email}</Text>
						{!user?.emailVerified && <SendVerificationEmailButton />}
					</Flex>
				</Card.Section>
				<Divider />
				<Card.Section p={8}>
					<Text>Username</Text>
					<Text size={"xl"}>{user?.displayName}</Text>
				</Card.Section>
				<SignOut />
			</Flex>
		</Card>
	)
}

export default Account
