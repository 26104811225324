import React, { ReactNode } from "react"
import { Modal, Text } from "@mantine/core"
import useHash from "../useHash"

const HashAwareModal = ({
  children,
  title,
}: {
  children: ReactNode
  title: string
}) => {
  const { hash, removeHash } = useHash()
  return (
    <Modal
      title={
        <Text size="xl" weight={700} style={{ marginBottom: 16 }}>
          {title}
        </Text>
      }
      opened={hash.startsWith("#getmoretokens")}
      onClose={removeHash}
      sx={{ zIndex: 10000 }}
    >
      {children}
    </Modal>
  )
}

export default HashAwareModal
