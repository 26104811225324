import React from "react"
import { Card } from "@mantine/core"
import GenderIcon from "./components/GenderIcon"
import CornerCircle from "./components/CornerCircle"
import AnimatedShineCard from "./components/AnimatedShineCard"
export {
  CHARACTER_CARD_DIMENSION_HEIGHT,
  CHARACTER_CARD_DIMENSION_WIDTH,
} from "./components/config"

const CharacterCardLoading = () => {
  return (
    <AnimatedShineCard autoPlay={true}>
      <Card.Section>
        <CornerCircle top={3} right={3}>
          <GenderIcon gender={"other"} />
        </CornerCircle>
      </Card.Section>
    </AnimatedShineCard>
  )
}

export default CharacterCardLoading
