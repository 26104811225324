import React, { useEffect } from "react"
import { ActionIcon, Flex, Select, Text } from "@mantine/core"
import { IconArrowsShuffle } from "@tabler/icons-react"
import { useFormContext } from "react-hook-form"
import { Attribute } from "../types"

type Properties = {
  name: string
  label: string
  placeholder?: string
  options: Attribute[]
}

const Selector = ({
  name,
  label,
  placeholder,
  options: defaultOptions,
}: Properties) => {
  const { setValue, watch } = useFormContext()
  const [options, setOptions] = React.useState<Attribute[]>(defaultOptions)

  const randomize = () => {
    setValue(name, randomOption(options), { shouldDirty: true })
  }

  useEffect(() => {
    if (
      watch(name).value &&
      !options.find((option) => option.value === watch(name).value)
    ) {
      setOptions([
        ...options,
        { value: watch(name).value, label: watch(name).value },
      ])
    }
  }, [])

  if (!watch(name)) return null

  return (
    <Select
      searchable
      creatable
      placeholder={placeholder || label}
      label={label}
      rightSection={
        <ActionIcon onClick={randomize}>
          <IconArrowsShuffle size={14} stroke={1.5} color="orange" />
        </ActionIcon>
      }
      sx={{ width: "100%" }}
      data={options}
      getCreateLabel={(query) => `+ Create ${query}`}
      onCreate={(query) => {
        const item = {
          value: query,
          label: query,
        }

        setOptions([...options, item])

        return item
      }}
      value={watch(name).value}
      onChange={(value) =>
        setValue(`${name}.value`, value, { shouldDirty: true })
      }
      itemComponent={SelectItem}
    />
  )
}

const randomOption = (options: Attribute[]) =>
  options[Math.floor(Math.random() * options.length)]

const SelectItem = ({
  label,
  description,
  ...rest
}: {
  label: string
  description: string
  selected: boolean
}) => {
  return (
    <>
      <Flex direction="column" p={8} {...rest}>
        <Text style={{ fontSize: "16px" }}>{label}</Text>
        {description && <Text style={{ fontSize: "12px" }}>{description}</Text>}
      </Flex>
      <hr style={{ margin: 0, opacity: 0.12 }} />
    </>
  )
}

export default Selector
