// src/components/Profile.tsx
import React from "react"
import { Text, Card, Flex, Loader } from "@mantine/core"
import useTokenBalance from "../../../../../api/tokens/useTokenBalance"
import TransactionHistoryAction from "./components/TransactionHistoryAction"
import AddTokensAction from "./components/AddTokensAction"

const Balance = () => {
  const { total, loading } = useTokenBalance()

  return (
    <div>
      <Card shadow="sm">
        <Flex gap={16} justify="center" direction={"column"}>
          <Flex direction={"column"} justify={"center"} align={"center"}>
            <Text size={"sm"}>Your Tokens</Text>
            {loading ? (
              <Loader size={24} />
            ) : (
              <Text
                style={{ fontWeight: 600 }}
                sx={{
                  fontSize: 56,
                }}
              >
                {total}
              </Text>
            )}
            <Flex gap={16} mt={8}>
              <AddTokensAction />
            </Flex>
            <TransactionHistoryAction />
          </Flex>
        </Flex>
      </Card>
    </div>
  )
}

export default Balance
