import React from "react"
import CreateCharacter from "./createCharacter/CreateCharacter"
// import AuthenticationBlock from "../../authentication/AuthenticationBlock"
// import VerificationBlock from "../../authentication/VerificationBlock"
import EmergencyBlock from "../../authentication/EmergencyBlock"

const CreateCharacterPage = () => {
  return (
    <div>
      <EmergencyBlock>
        {/* <VerificationBlock> */}
        <CreateCharacter />
        {/* </VerificationBlock> */}
      </EmergencyBlock>
    </div>
  )
}

export default CreateCharacterPage
