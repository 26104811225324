import { Button } from "@mantine/core"
import React from "react"
import createPaymentSession from "../../../../../api/payments/createPaymentSession"
import { logCreatePaymentSessionClicked } from "../../../../../firebase/analytics"

const CreatePaymentSessionButton = () => {
  const [loading, setLoading] = React.useState(false)

  const startCheckout = async () => {
    logCreatePaymentSessionClicked("get_more_tokens_modal")
    setLoading(true)
    const url = await createPaymentSession()
    setLoading(false)
    window.location.href = url
  }

  return (
    <Button
      loading={loading}
      onClick={startCheckout}
      fullWidth
      variant="outline"
    >
      Purchase more tokens!
    </Button>
  )
}

export default CreatePaymentSessionButton
