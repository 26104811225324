import { httpsCallable } from "firebase/functions"
import { functions } from "../../firebase"
import endpoints from "../endpoints"
import { Favorite } from "../../types"

const addFavorite = async (characterId: string) => {
  const { data: addedFavorite } = await httpsCallable<string, Favorite>(
    functions,
    endpoints.ADD_FAVORITE
  )(characterId)

  return addedFavorite
}

export default addFavorite
