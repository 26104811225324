import React from "react"
import { useFormContext } from "react-hook-form"
import ai from "../../../../../../api/ai"
import attributes from "../../attributes"
import { CharacterCreateSession, SettingOptions } from "../../../../../../types"
import { functions } from "../../../../../../firebase"
import Generator from "../../../../../../ui/generator/Generator"
import { useLoaderData } from "react-router-dom"
import { notifications } from "@mantine/notifications"

const PersonalityInput = () => {
  const { watch, setValue } = useFormContext()

  const { settingOptions } = useLoaderData() as {
    settingOptions: SettingOptions
  }

  const generateWithPrompt = async () => {
    try {
      const personality = await ai.generatePersonality(functions)({
        session: watch() as CharacterCreateSession,
      })

      setValue(`${attributes.personality}.value`, personality, {
        shouldDirty: true,
      })
    } catch (error) {
      notifications.show({
        title: "Error",
        message: "Something went wrong while generating personality",
        color: "red",
      })
    }
  }

  if (!settingOptions) return null

  return (
    <Generator
      name={attributes.personality}
      generate={generateWithPrompt}
      suggestions={suggestions}
    />
  )
}

export default PersonalityInput

const suggestions = [
  "Suspicious of authority",
  "Has a knack for telling captivating stories",
  "Always trying to diffuse tense situations with humor",
  "Obsessively organized and dislikes disorder",
  "Quiet and observant, always the last one to speak",
  "Finds it hard to trust others and is often standoffish",
  "Innately curious and always asking questions",
  "Prefers animals' company over humans",
  "Has a short temper and easily provoked",
  "Always sees the best in people, even when they don't deserve it",
  "Avoids conflict and seeks harmony",
  "Has an unquenchable thirst for knowledge",
  "Adventurous, always looking for the next thrill",
  "Charming and always able to get out of sticky situations",
  "Cynical and expects the worst in every situation",
  "Loyal to a fault and always stands by their friends",
  "Sarcastic, often using humor as a defense mechanism",
  "Optimistic, always looking at the bright side of life",
  "Overly cautious and always planning for every outcome",
  "Impulsive, often acts before thinking",
  "Fearless in the face of danger",
  "Has a gentle spirit, even in harsh circumstances",
  "Stoic and often hides emotions",
  "Extremely competitive, always wanting to be the best",
  "Flamboyant, enjoys being the center of attention",
  "Pessimistic, often struggling to see the positive side of things",
  "Devoted to a cause or belief",
  "Constantly daydreaming and lost in thought",
  "Relentlessly honest, even when it hurts",
  "Eccentric, known for odd behaviors and quirky habits",
]
