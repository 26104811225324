import React from "react"
import { Button } from "@mantine/core"
import { sendEmailVerification } from "firebase/auth"
import { auth } from "../../../../../../firebase"

const SendVerificationEmailButton = () => {
	const [loading, setLoading] = React.useState(false)

	const sendEmail = async () => {
		setLoading(true)
		if (auth.currentUser) await sendEmailVerification(auth.currentUser)
		setLoading(false)
	}

	return (
		<Button fullWidth variant="outline" loading={loading} onClick={sendEmail}>
			Send verification email
		</Button>
	)
}

export default SendVerificationEmailButton
