import endpoints from "../endpoints"
import { httpsCallable } from "firebase/functions"
import { Character, CharacterCreateSession } from "../../types"
import { functions } from "../../firebase"

const saveCharacter = async (
  session: CharacterCreateSession
): Promise<Character> => {
  const { data } = await httpsCallable<CharacterCreateSession, Character>(
    functions,
    endpoints.SAVE_CHARACTER
  )(session)

  return data as Character
}

export default saveCharacter
