const attributes = {
  name: "identifiers.name",
  age: "attributes.age",
  gender: "attributes.gender",
  alignment: "attributes.alignment",
  race: "attributes.race",
  class: "attributes.class",
  background: "attributes.background",
  story: "content.story",
  personality: "content.personality",
  appearance: {
    description: "content.appearance.description",
    image: "content.appearance.image",
  },
}

export default attributes
