import React from "react"
import usePaginatedInfinityCharacters from "./usePaginatedInfinityCharacters"

const emptyState = {
  characters: [],
  loading: false,
  refetch: async () => null,
  loadMoreIfNecessary: () => null,
}

export const ExplorePageContext = React.createContext<{
  refetchAllCharacters: () => void
  yours: ReturnType<typeof usePaginatedInfinityCharacters>
  published: ReturnType<typeof usePaginatedInfinityCharacters>
  yourFavorites: ReturnType<typeof usePaginatedInfinityCharacters>
  popular: ReturnType<typeof usePaginatedInfinityCharacters>
}>({
  refetchAllCharacters: async () => null,
  published: emptyState,
  yours: emptyState,
  yourFavorites: emptyState,
  popular: emptyState,
})
