import React from "react"
import { AppShell, Button, Flex, Space, Text } from "@mantine/core"
import { Link, Outlet, useNavigation } from "react-router-dom"
import TopHeader from "./components/TopHeader"
import GetMoreTokensModal from "./components/getMoreTokensModal/GetMoreTokensModal"
import NotificationSystem from "./components/notificationSystem/NotificationSystem"
import PageLoading from "./components/PageLoading"

const Shell = () => {
  const navigation = useNavigation()

  return (
    <AppShell
      padding={0}
      header={<TopHeader />}
      footer={
        <Flex
          w={"100vw"}
          h={36}
          bg={"#141517"}
          sx={{ bottom: 0, zIndex: 5 }}
          align={"center"}
          justify={"space-between"}
          gap={16}
          p={24}
        >
          <Link to={"/privacy-policy"}>
            <Button color="gray" compact variant="subtle">
              Privacy Policy
            </Button>
          </Link>
          <Text>Support: info@danion.app</Text>
        </Flex>
      }
    >
      <Space h={18} />
      {navigation.state === "loading" ? <PageLoading /> : <Outlet />}
      <GetMoreTokensModal />
      <NotificationSystem />
    </AppShell>
  )
}

export default Shell
