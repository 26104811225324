import React from "react"
import { useTranslation } from "react-i18next"
import Selector from "../../../../../../ui/Selector"
import attributes from "../../attributes"
import { useLoaderData } from "react-router-dom"
import { AttributeOptions } from "../../../../../../types"

const GenderSelect = () => {
  const { t } = useTranslation("generateCharacter")

  const {
    attributeOptions: { genders },
  } = useLoaderData() as {
    attributeOptions: AttributeOptions
  }

  return (
    <Selector
      name={attributes.gender}
      label={t("selector.gender.label")}
      options={genders}
    />
  )
}

export default GenderSelect
