import React from "react"
import { useFormContext } from "react-hook-form"
import {
  logSummonCharacterPromptEntered,
  logSummonCharacterPromptRandomized,
} from "../../../../../../firebase/analytics"
import { ActionIcon, TextInput } from "@mantine/core"
import { IconDice } from "@tabler/icons-react"

const PromptInput = ({ disabled }: { disabled: boolean }) => {
  const methods = useFormContext()

  const randomizePrompt = () => {
    const prompt = randomSuggestion()
    methods.setValue("prompt", prompt)
    logSummonCharacterPromptRandomized(prompt)
  }

  return (
    <TextInput
      size="md"
      disabled={disabled}
      style={{ width: "100%" }}
      rightSection={
        <ActionIcon onClick={randomizePrompt}>
          <IconDice />
        </ActionIcon>
      }
      onBlurCapture={() => {
        logSummonCharacterPromptEntered(methods.getValues().prompt)
      }}
      {...methods.register("prompt")}
    />
  )
}

export const randomSuggestion = () =>
  suggestions[Math.floor(Math.random() * suggestions.length)]

const suggestions = [
  "Invisible accordion-playing gnome seeking mythical creatures",
  "Enlightenment-seeking orc monk chef",
  "Tiefling ranger as spectral realm photographer",
  "Kleptomaniac halfling rogue dreaming of orphanage",
  "Pyrotechnic artist dragonborn sorcerer chasing ultimate spell",
  "Mechanical beast-transforming warforged druid",
  "Chess master elven wizard seeking animated set",
  "Crusading human paladin, former wrestling star",
  "Magical beer-brewing dwarf cleric",
  "Laughing Crystal questing aarakocra bard comedian",
  "Merfolk rogue with current-controlling trident",
  "High-stakes gambling, adventure-loving tabaxi bard",
  "Moonflower-hunting centaur ranger herbalist",
  "Redemption-seeking sunlight-resistant vampire sorcerer",
  "Divine truth questing minotaur paladin",
  "Miniature mechanical wonder crafting fairy",
  "Heights-fearing courageous goliath barbarian",
  "Voice-regaining missioned kenku cleric",
  "Reincarnated hero-believing lizardfolk druid",
  "Dancing-causing enchanted panpipes satyr rogue",
  "Telepathic silent ghostwise halfling monk",
  "Mystery-solving shape-shifting changeling detective",
  "Stereotype-defying eloquent orc bard",
  "Dusk tree-transforming elf ranger",
  "Familiar-to-hat turning human wizard",
  "Mischievous gnome rogue with a passion for baking.",
  "Grumpy dwarf cleric who moonlights as a winemaker.",
  "Eccentric halfling wizard obsessed with collecting hats.",
  "Brave centaur paladin with a fear of water.",
  "Sassy catfolk bard with a magical, disappearing violin.",
  "Wise old tortle monk with a love for storytelling.",
  "Rebellious tiefling sorcerer with a penchant for fireworks.",
  "Curious tabaxi ranger, explorer of forgotten ruins.",
  "Kind-hearted orc druid who talks to plants.",
  "Adventurous human fighter with a cursed, sentient sword.",
  "Naive aasimar warlock guided by an overly protective celestial.",
  "Crafty kenku thief with a collection of voices.",
  "Fierce dragonborn barbarian with a gentle heart for animals.",
  "Pirate triton sorcerer searching for a lost underwater city.",
  "Charming vampire bard seeking the world's best melodies.",
  "Inventive goblin artificer with a mech suit made of scrap.",
  "Ghostly spirit bard who doesn't realize they're dead.",
  "Caring firbolg vet who heals mythical creatures.",
  "Aarakocra courier with a love for high-speed flying.",
  "Time-traveling warlock with amnesia, solving their own mystery.",
  "Exiled elf alchemist obsessed with brewing the perfect potion.",
  "Bookish gnome illusionist running a magical library.",
  "Retired dwarf soldier turned pacifist flower farmer.",
  "Tiefling detective with a knack for solving magical mysteries.",
  "Wandering minotaur poet in search of inspiration.",
  "Lizardfolk chef with a taste for exotic cuisines.",
  "Merfolk bard on a quest to see the surface world.",
  "Genasi firefighter with control over elemental flames.",
  "Eladrin seasonal worker who changes jobs with the seasons.",
  "Hobgoblin lawyer navigating the intricacies of interspecies law.",
  "Curious pixie locksmith who can unlock any mystery.",
  "Haunted paladin seeking redemption for a forgotten sin.",
  "Rogue scientist gnome using alchemy to mimic magic.",
  "Nomadic elf ranger with a map to hidden realms.",
  "Dwarf blacksmith forging weapons imbued with magical runes.",
  "Mercurial shapeshifter with a secret identity in every town.",
  "Benevolent necromancer who only raises willing spirits.",
  "Half-elf journalist chronicling the unseen sides of wars.",
  "Pacifist barbarian trying to resolve conflicts through dialogue.",
  "Aspiring chef bard using music to flavor dishes.",
  "Blind seer warlock guided by an all-seeing familiar.",
  "Vigilante ranger cleaning up the streets of the capital.",
  "Celebrity gladiator fighter seeking a life beyond the arena.",
  "Disgraced noble rogue seeking to restore family honor.",
  "Cursed sorcerer turning into the elements they summon.",
  "Eternal student wizard in search of ultimate knowledge.",
  "Mute monk communicating through sign language and telepathy.",
  "Dimension-hopping thief stealing from across the multiverse.",
  "Angel-descended cleric doubting their divine mission.",
  "Hermit druid protecting an ancient and powerful secret.",
  "Time-displaced knight trying to adapt to modern customs.",
  "Undead pirate captain searching for redemption and peace.",
  "Magical tinkerer artificer inventing gadgets for everyday life.",
  "Celestial lawyer mediating disputes between gods and mortals.",
  "Goblin king hiding from bounty hunters in plain sight.",
  "Mystic librarian sorcerer guarding forbidden knowledge.",
  "Runaway prince paladin fighting against his tyrannical family.",
  "Elderly bard recounting tales of a forgotten age.",
  "Reformed thief rogue using skills for archaeological digs.",
  "Draconic chef seeking the perfect flame for cooking.",
  "Dream-walking wizard exploring the dreamscape for wisdom.",
  "Sylph dancer bard using performances to control the wind.",
  "Fallen angel warlock seeking a way back to grace.",
  "Primal druid shifting into extinct and mythical beasts.",
  "Psychic detective solving crimes before they happen.",
  "Cyborg artificer blending magic and technology.",
  "Unlikely hero commoner stepping up in a crisis.",
  "Dimensional researcher wizard cataloging alternate realities.",
  "Survivor of a lost land seeking a new home.",
  "Con artist bard with a heart of gold.",
  "Blade dancer fighting with elegance and precision.",
  "Plant mage druid with a sentient plant companion.",
  "Arcane hacker rogue infiltrating magical security systems.",
  "Troll bridge guardian turned environmental protector.",
  "Dwarven beer cleric brewing divine potions.",
  "Frost giant wizard shrinking to explore the human world.",
  "Owlfolk scholar deciphering the language of the stars.",
  "Fire spirit monk mastering martial arts to control flames.",
  "Vampire hunter paladin with a personal vendetta.",
  "Phantom thief with a flair for dramatic escapes.",
  "Whispering shadow monk using silence as a weapon.",
  "Fey-touched gardener druid with a sanctuary for mythical beasts.",
  "Storm-chasing wizard harnessing the power of lightning.",
  "Underground fighter monk with an unbeaten record.",
  "Arcane archivist seeking to catalog all magic spells.",
  "Displaced time-traveler trying to correct a broken timeline.",
  "Sky pirate captain with a heart for adventure.",
  "Ghostly bard serenading the living and the dead.",
  "Ice sculptor mage crafting sentient companions.",
  "Dimensional navigator exploring the fabric of reality.",
  "Runesmith dwarf encoding ancient powers in tattoos.",
  "Astral wanderer seeking their lost home among the stars.",
  "Beastmaster ranger with an unusual alien pet.",
  "Clockwork inventor creating life from gears and steam.",
  "Plague doctor cleric battling a magical disease.",
  "Sphinx librarian guarding riddles and secrets.",
  "Mindflayer psychologist delving into the psyches of others.",
  "Wandering judge paladin dispensing justice across lands.",
  "Elemental geologist studying the living essence of the earth.",
  "Rebel leader rogue sparking change in oppressed lands.",
  "Divine comedian bard spreading laughter like holy light.",
  "Star-crossed lover seeking a way to another dimension.",
  "Cave explorer ranger mapping the world's darkest depths.",
  "Revolutionary wizard using illusions to topple tyrants.",
  "Peace envoy cleric mediating between warring factions.",
  "Master of disguises rogue hiding multiple identities.",
  "Sun priest wielding light as a weapon and shield.",
  "Tinkerer's apprentice accidentally bonded with ancient machinery.",
  "Void walker warlock with eyes that see through dimensions.",
  "Gravekeeper druid nurturing life in places of death.",
  "Mystical painter capturing magical scenes that come to life.",
  "Celestial navigator guiding souls to their final rest.",
  "Demonic summoner seeking control over their own destiny.",
  "Arcane programmer coding spells into reality.",
  "Lost child of the Feywild searching for a way home.",
  "Historian mage unraveling the secrets of the past.",
  "Gladiator turned pacifist advocating for non-violence.",
  "Circus performer with a knack for enchantment magic.",
  "Wilderness survivalist with a bond to the land.",
  "Bounty hunter with a moral code and a mystery.",
  "Dragon slayer squire aiming to surpass their master.",
  "Cosmic singer whose voice bends the fabric of reality.",
  "Enchanted painter whose art comes to life.",
  "Legendary blacksmith crafting weapons for heroes and gods.",
  "Mountain hermit with ancient wisdom and powers.",
  "Sea witch controlling the tides and conversing with marine life.",
  "Portal mage opening doorways to unknown places.",
  "Blood mage seeking redemption for past misdeeds.",
  "Quantum theorist wizard experimenting with reality's boundaries.",
  "Shadow dancer thief moving through darkness as if light.",

  "Dwarven astronomer charting constellations with arcane precision.",
  "Elven sommelier with a taste for magical wines.",
  "Giant-slayer gnome with a grudge against tall odds.",
  "Celestial cartographer mapping the pathways between worlds.",
  "Arcane linguist deciphering lost languages of magic.",
  "Royal bodyguard sworn to protect a hidden prince.",
  "Desert nomad with secrets of the sands.",
  "Mountain sage seeking enlightenment atop the highest peaks.",
  "Urban druid advocating for green spaces in cities.",
  "Reformed bandit leading a troupe of merry outlaws.",
  "Puppeteer sorcerer animating objects with a whisper.",
  "Bounty hunter with a code against killing.",
  "Ethereal dancer weaving magic into every step.",
  "Firefighter mage specializing in magical blazes.",
  "Grave robber turned guardian of ancient tombs.",
  "Hedge witch with a potion for every ailment.",
  "Ice fisherman braving the coldest depths for magical creatures.",
  "Jester with the power to manifest illusions with laughter.",
  "Kobold inventor proving size isn't everything.",
  "Librarian fighting to preserve forbidden knowledge.",
  "Mushroom farmer cultivating magical fungi.",
  "Noble outcast seeking allies for a righteous cause.",
  "Opera singer whose voice can shatter spells.",
  "Pirate queen ruling the seas with a magic compass.",
  "Quantum sorcerer bending the laws of physics.",
  "Rune-carver with the power to bind elements.",
  "Spelljammer pilot navigating the astral sea.",
  "Tea master blending magical brews.",
  "Undercover agent in a kingdom of shadows.",
  "Vampire seeking the cure to their curse.",
  "Windwalker monk gliding on the breeze.",
  "Xylophonist bard playing melodies that enchant the wild.",
  "Yogi mastering ancient techniques for mystical power.",
  "Zookeeper for magical creatures, both cute and fearsome.",
  "Archaeologist uncovering relics of spellcasting civilizations.",
  "Bioluminescent merfolk illuminating the ocean's depths.",
  "Cosplay mage transforming into legends and myths.",
  "Dreamweaver weaving spells from the fabric of dreams.",
  "Eco-warrior druid fighting against unnatural corruption.",
  "Falconer with a mythical bird of prey.",
  "Gemstone collector trading in magical crystals.",
  "Herbalist with knowledge of forgotten remedies.",
  "Ice sculptor mage freezing moments in time.",
  "Juggler distracting foes with mesmerizing skill.",
  "Kite flyer sending messages across the skies.",
  "Luthier crafting instruments that play themselves.",
  "Meteorologist predicting and controlling weather with magic.",
  "Necromancer seeking to undo a great wrong.",
  "Oracle with cryptic visions of multiple futures.",
  "Pilgrim on a quest for a divine relic.",
]

export default PromptInput
