import profile from "./profile.json"
import donate from "./donate.json"
import general from "./general.json"
import generateCharacter from "./generateCharacter.json"
import explore from "./explore.json"

const english = {
	profile,
	donate,
	general,
	generateCharacter,
	explore,
}

export default english
