import {
	IconGenderMale,
	IconGenderFemale,
	IconGenderBigender,
	IconGenderAgender,
} from "@tabler/icons-react"
import React from "react"

type Properties = {
	gender: string
}

const GenderIcon = ({ gender }: Properties) => {
	const Icon = getGenderIcon(gender)

	return (
		<div style={{ height: "1.5rem" }}>
			<Icon />
		</div>
	)
}

const getGenderIcon = (gender: string) => {
	switch (gender) {
		case "Male":
			return IconGenderMale
		case "Female":
			return IconGenderFemale
		case "Non-binary":
			return IconGenderBigender
		default:
			return IconGenderAgender
	}
}

export default GenderIcon
