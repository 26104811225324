import React, { useContext } from "react"
import useHash from "./useHash"
import TokensPurchaseSuccessModal from "./components/TokensPurchaseSuccessModal"
import TokensPurchaseCanceledModal from "./components/TokensPurchaseCanceledModal"
import OutOfTokensModal from "./components/OutOfTokensModal"
// import EncourageMoreTokensModal from "./components/EncourageMoreTokensModal"
import { UserContext } from "../../../../global/user"
import EncourageSignUpModal from "./components/EncourageSignUpModal"

const GetMoreTokensModal = () => {
  const { hash } = useHash()
  const user = useContext(UserContext)

  if (!user || user.isAnonymous) return <EncourageSignUpModal />
  if (hash.includes("success=true")) return <TokensPurchaseSuccessModal />
  if (hash.includes("canceled=true")) return <TokensPurchaseCanceledModal />
  if (!isLessThanThreeMinutesOld(user?.metadata?.creationTime))
    return <OutOfTokensModal />
  return null
}

const isLessThanThreeMinutesOld = (creationTime: string | undefined) => {
  if (!creationTime) return false
  const creationDate = new Date(creationTime)
  const now = new Date()
  const diff = now.getTime() - creationDate.getTime()
  const diffInMinutes = diff / (1000 * 60)
  return diffInMinutes < 3
}

export default GetMoreTokensModal
