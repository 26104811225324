import React from "react"
import { Badge, Flex } from "@mantine/core"
import { Character } from "../../../../types"
import { IconHeartFilled } from "@tabler/icons-react"

export const YourCharacterBadges = ({
  character,
}: {
  character: Character
}) => {
  return (
    <Flex
      direction="row"
      gap={8}
      py={16}
      px={8}
      style={{ position: "absolute", zIndex: 4 }}
    >
      <FavoritesBadge character={character} />
      <PublishedBadge character={character} />
    </Flex>
  )
}

export const PublishedCharacterBadges = ({
  character,
}: {
  character: Character
}) => {
  return (
    <Flex
      direction="row"
      gap={8}
      style={{ position: "absolute", zIndex: 4, top: 30, left: 30 }}
    >
      <FavoritesBadge character={character} />
    </Flex>
  )
}

const FavoritesBadge = ({ character }: { character: Character }) => {
  const favoriteCount = character.favoriteCount

  return favoriteCount ? (
    <Badge
      variant="filled"
      size="md"
      color="dark"
      rightSection={<IconHeartFilled size={8} />}
    >
      {favoriteCount}
    </Badge>
  ) : null
}

const PublishedBadge = ({ character }: { character: Character }) => {
  return character.status === "published" ? (
    <Badge variant="filled" size="md" color="lime">
      Published
    </Badge>
  ) : null
}
