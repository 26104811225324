import React from "react"
import { Flex } from "@mantine/core"

const HeroBanner = ({ children }: { children: React.ReactNode }) => {
  const windowWidth = window.innerWidth

  return (
    <Flex
      direction="column"
      style={{
        alignItems: "center",
        justifyContent: "center",
        minHeight: "60vh",
        backgroundImage: `linear-gradient(rgba(36,36,36,0), rgba(36,36,36,0.5)), url(${selectBackgroundImage(
          windowWidth
        )})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      {children}
    </Flex>
  )
}

const selectBackgroundImage = (windowWidth: number) => {
  if (windowWidth < 600) return smallBackgroundImage
  if (windowWidth < 900) return mediumBackgroundImage
  return largeBackgroundImage
}

const smallBackgroundImage =
  "https://firebasestorage.googleapis.com/v0/b/danion-development.appspot.com/o/general%2Fsummon_background_720.avif?alt=media&token=f50b1d07-a84e-4881-8dcb-14b596bd10bf"

const mediumBackgroundImage =
  "https://firebasestorage.googleapis.com/v0/b/danion-development.appspot.com/o/general%2Fsummon_background_1080.avif?alt=media&token=44854f1f-97cb-48e4-9113-d692f9fddc84"

const largeBackgroundImage =
  "https://firebasestorage.googleapis.com/v0/b/danion-development.appspot.com/o/general%2Fsummon_background_1444.avif?alt=media&token=f99e83cd-775d-4f1d-b331-57071fdd7101"

export default HeroBanner
