import { logEvent } from "firebase/analytics"
import { analytics } from "./index"

export const logNavigateToCreateCharacter = () =>
  logEvent(analytics, "navigate_to_create_character")

export const logNavigateToProfile = () =>
  logEvent(analytics, "navigate_to_profile")

export const logNavigateToExplore = () =>
  logEvent(analytics, "navigate_to_explore")

export const logCreatePaymenSessionEvent = () =>
  logEvent(analytics, "create_payment_session")

export const logCharacterCardClickEvent = (characterId: string) =>
  logEvent(analytics, "character_card_click", { characterId })

export const logCharacterPageViewEvent = (characterId: string) =>
  logEvent(analytics, "character_page_view", { characterId })

export const logBalanceTransactionHistoryClickEvent = () =>
  logEvent(analytics, "balance_transaction_history_click")

export const logGenerateButtonClickedEvent = () =>
  logEvent(analytics, "generate_button_clicked", {
    location: window.location.href,
  })

export const logSendButtonClickedEvent = () =>
  logEvent(analytics, "send_button_clicked", { location: window.location.href })

export const logSummonCharacterPromptEntered = (prompt: string) =>
  logEvent(analytics, "summon_character_prompt_entered", { prompt })

export const logSummonCharacterPromptRandomized = (prompt: string) =>
  logEvent(analytics, "summon_character_prompt_randomized", { prompt })

export const logSummonCharacterButtonClicked = (prompt: string) =>
  logEvent(analytics, "summon_character_button_clicked", { prompt })

export const logCharacterModalEditActionClicked = (
  id: string,
  location: string
) =>
  logEvent(analytics, "character_modal_edit_action_clicked", { id, location })

export const logCharacterModalFavoriteActionClicked = (id: string) =>
  logEvent(analytics, "character_modal_favorite_action_clicked", { id })

export const logCharacterModalRemoveActionClicked = (id: string) =>
  logEvent(analytics, "character_modal_remove_action_clicked", { id })

export const logCreatePaymentSessionClicked = (location: string) =>
  logEvent(analytics, "create_payment_session_clicked", { location })
