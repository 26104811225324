import { httpsCallable } from "firebase/functions"
import { functions } from "../../firebase"
import endpoints from "../endpoints"
import { Favorite } from "../../types"

const removeFavorite = async (characterId: string) => {
  const { data: removedFavorite } = await httpsCallable<string, Favorite>(
    functions,
    endpoints.REMOVE_FAVORITE
  )(characterId)

  return removedFavorite
}

export default removeFavorite
