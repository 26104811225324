import { Functions, httpsCallable } from "firebase/functions"
import { CharacterCreateSession, Image } from "../../types"
import endpoints from "../endpoints"
import { notifications } from "@mantine/notifications"

type Properties = {
  session: CharacterCreateSession
}

const generateAppearenceImage =
  (functions: Functions) =>
  async ({ session }: Properties): Promise<Image | undefined> => {
    try {
      const { data: image } = await httpsCallable<Properties, Image>(
        functions,
        endpoints.GENERATE_APPEARANCE_IMAGE
      )({ session })
      return image as Image
    } catch (error) {
      console.error(error)

      notifications.show({
        title: "Error",
        message: "Failed to generate appearence",
      })
    }
  }

export default generateAppearenceImage
