import React, { useContext } from "react"
import {
  Image,
  ActionIcon,
  Text,
  Header,
  Flex,
  Avatar,
  Loader,
} from "@mantine/core"
import { IconUser } from "@tabler/icons-react"
import { useNavigate } from "react-router-dom"
import useTokenBalance from "../../../api/tokens/useTokenBalance"
import {
  logNavigateToExplore,
  logNavigateToProfile,
} from "../../../firebase/analytics"
import { UserContext } from "../../../global/user"
import ProfileDrawer from "../../routes/profile/ProfileDrawer"

const TopHeader = () => {
  const { total, loading } = useTokenBalance()

  const user = useContext(UserContext)
  const navigate = useNavigate()

  const [opened, setOpened] = React.useState(false)

  const navigateToHome = () => {
    logNavigateToExplore()
    navigate("/")
  }

  // const navigateToNewSession = () => {
  //   navigate("/session/create")
  // }

  return (
    <Header height={36} sx={{ zIndex: 5 }}>
      <div
        style={{
          display: "inline-flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          padding: "8px",
          position: "fixed",
          top: "0",
          backgroundColor: "#141517",
        }}
      >
        <ActionIcon
          color="orange"
          variant="transparent"
          size="lg"
          onClick={navigateToHome}
        >
          <Image
            radius="md"
            src={
              "https://firebasestorage.googleapis.com/v0/b/danion-development.appspot.com/o/general%2Fdnd_logo.webp?alt=media&token=6bfda28a-b18e-4f90-98dc-908701db8124"
            }
            alt="Danion logo"
            width="3rem"
            height="3rem"
            fit="contain"
          />
        </ActionIcon>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "1rem",
            alignItems: "center",
            height: "100%",
          }}
        >
          {/* <MediaQuery smallerThan="xs" styles={{ display: "none" }}>
            <Button
              variant="light"
              color="orange"
              size="sm"
              leftIcon={<IconPlus />}
              onClick={navigateToNewSession}
            >
              New Session
            </Button>
          </MediaQuery> */}

          {/* <MediaQuery largerThan="xs" styles={{ display: "none" }}>
            <ActionIcon
              color="orange"
              variant="light"
              size={34}
              onClick={navigateToNewSession}
            >
              <IconBook />
            </ActionIcon>
          </MediaQuery> */}

          <div
            onClick={() => {
              logNavigateToProfile()
              setOpened(true)
            }}
            style={{
              textDecoration: "none",
              color: "#ffd8a8",
            }}
          >
            {user ? (
              <Flex
                style={{
                  backgroundColor: "rgba(232, 89, 12, 0.2)",
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "16px",
                  alignItems: "center",
                  height: "100%",
                  borderRadius: "56px",
                  paddingLeft: "8px",
                }}
              >
                <Flex align={"center"} gap={6}>
                  <Image
                    width={20}
                    height={20}
                    m={8}
                    src={
                      "https://firebasestorage.googleapis.com/v0/b/danion-development.appspot.com/o/general%2Fdanion-token.avif?alt=media&token=d4952c53-b112-4f73-8304-038a5f53779f"
                    }
                  />
                  {loading && <Loader size={"xs"} />}
                  {total ? (
                    <Text>{Number(total).toLocaleString("en")}</Text>
                  ) : null}
                </Flex>
                <Avatar size={"md"} radius={"xl"}>
                  <IconUser color="#ffd8a8" size={20} />
                </Avatar>
              </Flex>
            ) : (
              <Avatar size={"md"} radius={"xl"}>
                <IconUser color="#ffd8a8" size={20} />
              </Avatar>
            )}
          </div>
        </div>
      </div>
      <ProfileDrawer opened={opened} onClose={() => setOpened(false)} />
    </Header>
  )
}

export default TopHeader
