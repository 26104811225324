import React from "react"
// import { Group } from "@mantine/core"
import { useFormContext } from "react-hook-form"
// import ClearTextButton from "../../../../../../ui/ClearTextButton"
// import CopyTextButton from "../../../../../../ui/CopyTextButton"
// import GenerateButton from "../../../../../../ui/GenerateButton"
// import MultiLineInput from "../../../../../../ui/MultiLineInput"
import ai from "../../../../../../api/ai"
import attributes from "../../attributes"
import { CharacterCreateSession } from "../../../../../../types"
// import { useTranslation } from "react-i18next"
import { functions } from "../../../../../../firebase"
// import SingleLineInput from "../../../../../../ui/SingleLineInput"
import Generator from "../../../../../../ui/generator/Generator"

const AppearenceInput = () => {
  const { watch, setValue } = useFormContext()

  const generateWithPrompt = async () => {
    const description = await ai.generateAppearenceDescription(functions)({
      session: watch() as CharacterCreateSession,
    })

    setValue(`${attributes.appearance.description}.value`, description, {
      shouldDirty: true,
    })
  }

  // const generateEdit = async () => {
  //   const { description, image } = await ai.generateAppearenceEdit(functions)(
  //     watch() as Character,
  //     watch("editImagePrompt"),
  //     watch("mask")
  //   )
  //   setValue(attributes.appearance.description, description)
  //   if (image) setValue(attributes.appearance.image, image)
  // }

  // const clear = () => {
  //   setValue(attributes.appearance.description, "")
  // }

  return (
    <>
      <Generator
        name={attributes.appearance.description}
        generate={generateWithPrompt}
        suggestions={suggestions}
      />
      {/* <MultiLineInput
        name={attributes.appearance.description}
        label={t("input.appearance.label")}
        placeholder={t("input.appearance.placeholder") as string}
      />
      <Group position="right">
        <ClearTextButton
          clear={clear}
          value={watch(attributes.appearance.description)}
        />
        <CopyTextButton value={watch(attributes.appearance.description)} />
        <GenerateButton cost={2} generate={generate} />
      </Group>
      {watch(attributes.appearance.image) && (
        <Group>
          <SingleLineInput label={"Edit image prompt"} name="editImagePrompt" />
          <GenerateButton cost={2} generate={generateEdit} />
        </Group>
      )} */}
    </>
  )
}

const suggestions = [
  "Armored, shield-bearing figure, emblemless and ready for battle.",
  "Hooded, pocket-lined garb, carrying trinkets and tools.",
  "Wide-brimmed hat, scarf-covered mouth, eyes of knowledge.",
  "Sturdy tunic, belt-pouched and practically prepared.",
  "Feather and bone-adorned coat, shamanic yet scholarly.",
  "Color-shifting robe, carrying a deceptively ordinary staff.",
  "Magic-humming boots, unadorned cape, silent mystique.",
  "Minimalist, armored, sword-hipped warrior.",
  "Tattered cloak, imposing unassuming solitary figure.",
  "Leather-clad, hook-and-rope equipped explorer.",
  "Chainmail shawl, pendant-clad, power-focused.",
  "Elegant vestment hiding light armor, noble readiness.",
  "Flamboyant yet battle-ready, lute-backed bard.",
  "Hooded robe, sleeves hiding mysterious devices.",
  "Gadget-belted artificer, utilitarian and mysterious.",
  "Simple, patterned cloak, tome-chained spellcaster.",
  "Camouflaged ranger, endless quiver and bow.",
  "Life-symbol robe, peace-staffed healer.",
  "Adventurous spark, scarf-wrapped nomad.",
  "Weathered tricorn, deep blue coat, destiny's compass bearer.",
]

export default AppearenceInput
