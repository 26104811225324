import { Functions, httpsCallable } from "firebase/functions"
import { Appearence, Character } from "../../types"
import endpoints from "../endpoints"
import { notifications } from "@mantine/notifications"

type Parameters = { character: Character; prompt: string; mask: string }

const generateAppearenceEdit =
  (functions: Functions) =>
  async (
    character: Character,
    prompt: string,
    mask: string
  ): Promise<Appearence> => {
    try {
      const {
        data: { description, image },
      } = await httpsCallable<Parameters, Appearence>(
        functions,
        endpoints.GENERATE_APPEARANCE_EDIT
      )({ character, prompt, mask })
      return { description, image }
    } catch (error) {
      console.error(error)
      notifications.show({
        title: "Error",
        message: "Failed to generate appearence",
      })
      return character.content.appearance
    }
  }

export default generateAppearenceEdit
