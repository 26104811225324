import { functions } from "../../firebase"
import { httpsCallable } from "firebase/functions"
import { Character } from "../../types"
import endpoints from "../endpoints"

const browsePopularCharacters = async (
  query: string | null,
  cursor: number,
  startCount: number,
  loadCount: number
) => {
  const { data: characters } = await httpsCallable(
    functions,
    endpoints.BROWSE_POPULAR_CHARACTERS
  )({ query, limit: cursor ? loadCount : startCount, cursor })
  return characters as Character[]
}

export default browsePopularCharacters
