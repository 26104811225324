import {
  collection,
  getCountFromServer,
  query,
  where,
} from "firebase/firestore"
import { firestore } from "../../firebase"

const countFavorites = async (characterId: string) => {
  const snapshot = await query(
    collection(firestore, "favorites"),
    where("character", "==", characterId),
    where("active", "==", true)
  )

  const res = getCountFromServer(snapshot)

  return (await res).data().count
}

export default countFavorites
