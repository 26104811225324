import { LoaderFunctionArgs } from "react-router-dom"
import characterCreateSession from "../../api/character/characterCreateSession"

const characterCreateLoader = async ({ params }: LoaderFunctionArgs) => {
  const { id } = params as { id: string }

  const { session, attributeOptions, settingOptions } =
    await characterCreateSession(id)

  return { session, attributeOptions, settingOptions }
}

export default characterCreateLoader
