import React from "react"
import HashAwareModal from "./HashAwareModal"
import { Flex, Text } from "@mantine/core"
import CreatePaymentSessionButton from "./CreatePaymentSessionButton"

const OutOfTokensModal = () => {
  return (
    <HashAwareModal title="Too few tokens 😱">
      <Flex direction="column" align="center" gap={16}>
        <Text style={{ marginBottom: 24 }}>
          You have too few tokens to do this action. Get more tokens to create
          more characters and explore limitless possibilities.
        </Text>
        <CreatePaymentSessionButton />
      </Flex>
    </HashAwareModal>
  )
}

export default OutOfTokensModal
