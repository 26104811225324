import React from "react"
import { Character } from "../../../../../../types"
import { useMediaQuery } from "@mantine/hooks"
import { Flex, Text } from "@mantine/core"
import AttributeBadge from "../../../../../../ui/characterCard/components/AttributeBadge"
import Paragraphs from "./Paragraphs"

const Attributes = ({ character }: { character: Character }) => {
  const isMobile = useMediaQuery("(max-width: 600px)")
  return (
    <Flex
      mt={8}
      w={"100%"}
      h={"100%"}
      direction="column"
      justify={"space-between"}
      gap={8}
    >
      <div>
        <AttributeRow label="Species" attribute={character.attributes?.race} />
        <AttributeRow label="Age" attribute={character.attributes?.age} />
        <AttributeRow label="Gender" attribute={character.attributes?.gender} />
        <AttributeRow label="Class" attribute={character.attributes?.class} />
        <AttributeRow
          label="Background"
          attribute={character.attributes?.background}
        />
        <hr style={{ width: "100%" }} />
        <div
          style={{
            height: isMobile ? undefined : "240px",
            overflow: "auto",
          }}
        >
          <Paragraphs text={character.summary} />
        </div>
      </div>
    </Flex>
  )
}

const AttributeRow = ({
  attribute,
  label,
}: {
  attribute: string
  label: string
}) => {
  return (
    <Flex direction="row" w={"100%"} justify={"space-between"} align={"center"}>
      <Text>{label}</Text>
      <AttributeBadge attribute={attribute} />
    </Flex>
  )
}

export default Attributes
