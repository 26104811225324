import endpoints from "../endpoints"
import { httpsCallable, Functions } from "firebase/functions"
import { CharacterCreateSession } from "../../types"

const generateName =
  (functions: Functions) =>
  async (session: CharacterCreateSession): Promise<string> => {
    try {
      const { data: name } = await httpsCallable<
        CharacterCreateSession,
        string
      >(
        functions,
        endpoints.GENERATE_NAME
      )(session)
      return name
    } catch (error) {
      console.error(error)
      return "No name generated"
    }
  }

export default generateName
