import React from "react"
import { useClickOutside } from "@mantine/hooks"
import Controls from "./components/Controls"
import Suggestions from "./components/Suggestions"
import Prompt from "./components/Prompt"
import { useFormContext } from "react-hook-form"
import { TextState } from "../../../../../../types"

type Properties = {
  name: string
  suggestions: string[]
  generate: (state: TextState) => Promise<void>
}

const Input = ({ name, generate, suggestions }: Properties) => {
  const { watch, setValue } = useFormContext()

  const [promptHistory, setPromptHistory] = React.useState<string[]>([])
  const [active, setActive] = React.useState(false)
  const clickOutsideRef = useClickOutside(() => setActive(false))

  const generateWithPrompt = async () => {
    await generate(watch(name))
    setPromptHistory([...promptHistory, watch(name).prompt])
    setValue(`${name}.prompt`, "")
  }

  const revertToLatestPrompt = () => {
    setValue(promptHistory.pop() || "", {
      shouldDirty: true,
    })
    setPromptHistory(promptHistory.slice(0, promptHistory.length))
  }

  const resetPrompt = () => {
    setValue(`${name}.prompt`, "")
  }

  return (
    <div ref={clickOutsideRef}>
      <Suggestions
        name={name}
        setActive={setActive}
        suggestions={suggestions}
      />
      <Prompt name={name} setActive={setActive} active={active} />
      <Controls
        generate={generateWithPrompt}
        revertToLatestPrompt={revertToLatestPrompt}
        resetPrompt={resetPrompt}
        active={active}
        hasHistory={promptHistory.length > 0}
        hasPrompt={prompt.length > 0}
      />
    </div>
  )
}
export default Input
