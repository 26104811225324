import endpoints from "../endpoints"
import { httpsCallable, Functions } from "firebase/functions"
import { CharacterCreateSession } from "../../types"

type Properties = {
  session: CharacterCreateSession
}

const generatePersonality =
  (functions: Functions) =>
  async ({ session }: Properties): Promise<string> => {
    const { data: personality } = await httpsCallable<Properties, string>(
      functions,
      endpoints.GENERATE_PERSONALITY
    )({ session })

    return personality
  }

export default generatePersonality
