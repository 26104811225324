import React from "react"
import HashAwareModal from "./HashAwareModal"
import { Flex, Text } from "@mantine/core"
import AuthenticationTabs from "../../../../authentication/components/AuthenticationTabs"

const EncourageSignUpModal = () => {
  return (
    <HashAwareModal title="Sign up to use all features!">
      <Flex direction="column" align="center" gap={16}>
        <Text style={{ marginBottom: 24 }}>
          You get 25 more free tokens upon sign up to try everything out, like
          talking to your characters!
        </Text>
        <AuthenticationTabs />
      </Flex>
    </HashAwareModal>
  )
}

export default EncourageSignUpModal
