import { httpsCallable } from "firebase/functions"
import { functions } from "../../firebase"
import endpoints from "../endpoints"
import { Favorite } from "../../types"

const getFavorite = async (characterId: string) => {
  const { data: activeFavorite } = await httpsCallable<string, Favorite>(
    functions,
    endpoints.GET_FAVORITE
  )(characterId)

  return activeFavorite
}

export default getFavorite
