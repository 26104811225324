import React from "react"
import { Flex, Image, Text } from "@mantine/core"

const PageLoading = () => {
  return (
    <Flex
      sx={{
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: 99999,
        width: "100vw",
        height: "100vh",
        backgroundColor: "rgba(0,0,0,0.5)",
      }}
      align={"center"}
      justify={"center"}
      direction={"column"}
      gap={8}
    >
      <Image
        radius="md"
        src={
          "https://firebasestorage.googleapis.com/v0/b/danion-development.appspot.com/o/general%2Fdnd_logo.webp?alt=media&token=6bfda28a-b18e-4f90-98dc-908701db8124"
        }
        alt="Danion logo"
        width="3rem"
        height="3rem"
        fit="contain"
        sx={{
          animation: "fade 1s linear infinite",
          "@keyframes fade": {
            "0%": {
              opacity: 0.2,
            },
            "50%": {
              opacity: 1,
            },
            "100%": {
              opacity: 0.2,
            },
          },
        }}
      />
      <Text>Loading</Text>
    </Flex>
  )
}

export default PageLoading
