import { ActionIcon, Button, Flex } from "@mantine/core"
import { IconArrowBackUp, IconArrowForwardUp } from "@tabler/icons-react"
import React, { useRef, useState } from "react"
import { useFormContext } from "react-hook-form"
import ai from "../../../api/ai"
import { functions } from "../../../firebase"
import { CharacterCreateSession } from "../../../types"

const MaskingCanvas: React.FC = () => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null)

  const [drawing, setDrawing] = useState<boolean>(false)
  const [points, setPoints] = useState<{ x: number; y: number }[]>([])
  const [startPoint, setStartPoint] = useState<{ x: number; y: number } | null>(
    null
  )

  const { setValue } = useFormContext()

  const clearCanvas = () => {
    if (canvasRef.current) {
      const ctx = canvasRef.current.getContext("2d")
      if (ctx)
        ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height)
    }
  }

  const startDrawing = (e: React.MouseEvent<HTMLCanvasElement>) => {
    clearCanvas() // Clear canvas when user starts drawing

    const mouseX = e.nativeEvent.offsetX
    const mouseY = e.nativeEvent.offsetY

    setStartPoint({ x: mouseX, y: mouseY })
    setPoints([{ x: mouseX, y: mouseY }])
    setDrawing(true)
  }

  const draw = (e: React.MouseEvent<HTMLCanvasElement>) => {
    if (!drawing) return
    const mouseX = e.nativeEvent.offsetX
    const mouseY = e.nativeEvent.offsetY

    setPoints((prev) => {
      const newPoints = [...prev, { x: mouseX, y: mouseY }]
      if (canvasRef.current) {
        const ctx = canvasRef.current.getContext("2d")
        if (ctx) {
          ctx.lineTo(mouseX, mouseY)
          ctx.stroke() // Draw the line as the user moves the mouse
        }
      }
      return newPoints
    })
  }

  const stopDrawing = () => {
    setDrawing(false)

    if (!canvasRef.current || !startPoint) return
    const ctx = canvasRef.current.getContext("2d")

    // Clear the canvas before applying shading
    clearCanvas()

    if (points.length < 5) {
      setPoints([])
      setValue("mask", null, { shouldDirty: true })
      return
    }

    if (ctx) {
      ctx.fillStyle = "#000000"
      ctx.globalAlpha = 0.48
      ctx.fillRect(0, 0, canvasRef.current.width, canvasRef.current.height)
      ctx.globalAlpha = 1 // Reset alpha

      ctx.beginPath()
      ctx.moveTo(startPoint.x, startPoint.y)

      points.forEach((point) => {
        ctx.lineTo(point.x, point.y)
      })

      ctx.closePath()

      ctx.globalCompositeOperation = "destination-out"
      ctx.fill()

      ctx.globalCompositeOperation = "source-over"
    }

    setPoints([])

    // size up the canvas to 1024x1024

    const tempCanvas = document.createElement("canvas")
    tempCanvas.width = 1024
    tempCanvas.height = 1024
    const tempCtx = tempCanvas.getContext("2d")
    if (!tempCtx) return
    tempCtx.drawImage(
      canvasRef.current,
      0,
      0,
      canvasRef.current.width,
      canvasRef.current.height,
      0,
      0,
      tempCanvas.width,
      tempCanvas.height
    )

    setValue("mask", tempCanvas.toDataURL(), { shouldDirty: true })
  }

  return (
    <div
      style={{
        height: 256,
        width: 256,
        position: "absolute",
        zIndex: 4,
        margin: "auto",
      }}
    >
      <ImageGenerationControls />
      <canvas
        ref={canvasRef}
        width={256}
        height={256}
        style={{ position: "absolute", zIndex: 2 }}
        onMouseDown={startDrawing}
        onMouseMove={draw}
        onMouseUp={stopDrawing}
      />
    </div>
  )
}

const ImageGenerationControls = () => {
  const { watch, setValue } = useFormContext()

  const [imageHistoryCursor, setImageHistoryCursor] = useState<number>(0)

  const [isGenerating, setIsGenerating] = useState(false)

  const isOnLastImage =
    imageHistoryCursor === watch("content.appearance.image.history").length - 1

  const isOnFirstImage = !isOnLastImage && imageHistoryCursor === 0

  const generateNewImage = async () => {
    setIsGenerating(true)
    const image = await ai.generateAppearenceImage(functions)({
      session: watch() as CharacterCreateSession,
    })

    const imageHistory = [...watch("content.appearance.image.history"), image]

    setValue("content.appearance.image.value", image, { shouldDirty: true })
    setValue("content.appearance.image.history", imageHistory, {
      shouldDirty: true,
    })

    setImageHistoryCursor(imageHistory.length - 1)

    setIsGenerating(false)
  }

  const getPreviousImage = async () => {
    if (!isOnFirstImage) {
      const prevImageCursor = imageHistoryCursor - 1

      setImageHistoryCursor(prevImageCursor)

      const previousImage = watch("content.appearance.image.history")[
        prevImageCursor
      ]

      setValue("content.appearance.image.value", previousImage, {
        shouldDirty: true,
      })
    }
  }

  const getNextImage = async () => {
    if (!isOnLastImage) {
      const nextImageCursor = imageHistoryCursor + 1

      setImageHistoryCursor(nextImageCursor)

      const nextImage = watch("content.appearance.image.history")[
        nextImageCursor
      ]

      setValue("content.appearance.image.value", nextImage, {
        shouldDirty: true,
      })
    }
  }

  return (
    <Flex
      sx={{
        position: "absolute",
        bottom: 8,
        left: 8,
        zIndex: 3,
      }}
      w={"100%"}
      align={"center"}
      justify={"space-between"}
    >
      <ActionIcon
        variant="filled"
        onClick={getPreviousImage}
        disabled={
          !watch("content.appearance.image.value") || imageHistoryCursor === 0
        }
      >
        <IconArrowBackUp />
      </ActionIcon>
      <Button
        variant="filled"
        color="gray"
        compact
        sx={{
          margin: "0 auto",
          minWidth: 100,
        }}
        disabled={!watch("content.appearance.description.value")}
        loading={isGenerating}
        onClick={generateNewImage}
      >
        Generate
      </Button>
      <ActionIcon
        variant="filled"
        onClick={getNextImage}
        disabled={
          !watch("content.appearance.image") ||
          imageHistoryCursor ===
            watch("content.appearance.image.history").length - 1
        }
      >
        <IconArrowForwardUp />
      </ActionIcon>
    </Flex>
  )
}

export default MaskingCanvas
