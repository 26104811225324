import React from "react"
import { Character } from "../../../../../../types"
import { Accordion } from "@mantine/core"
import { IconBook, IconBrain, IconPhoto } from "@tabler/icons-react"
import Paragraphs from "./Paragraphs"

const Content = ({ character }: { character: Character }) => {
  return (
    <Accordion
      multiple
      defaultValue={
        !character.status || character.status === "summoning"
          ? ["story", "personality", "appearance"]
          : []
      }
    >
      <Accordion.Item value="appearance">
        <Accordion.Control icon={<IconPhoto size={20} />}>
          Appearance
        </Accordion.Control>
        <Accordion.Panel>
          <Paragraphs text={character.content?.appearance?.description} />
        </Accordion.Panel>
      </Accordion.Item>
      <Accordion.Item value="story">
        <Accordion.Control icon={<IconBook size={20} />}>
          Story
        </Accordion.Control>
        <Accordion.Panel>
          <Paragraphs text={character.content?.story} />
        </Accordion.Panel>
      </Accordion.Item>
      <Accordion.Item value="personality">
        <Accordion.Control icon={<IconBrain size={20} />}>
          Personality
        </Accordion.Control>
        <Accordion.Panel>
          <Paragraphs text={character.content?.personality} />
        </Accordion.Panel>
      </Accordion.Item>
    </Accordion>
  )
}

export default Content
