import endpoints from "../endpoints"
import { httpsCallable } from "firebase/functions"
import { Character } from "../../types"
import { functions } from "../../firebase"

const removeCharacter = async (character: Character) => {
  const { data } = await httpsCallable<string, string>(
    functions,
    endpoints.REMOVE_CHARACTER
  )(character.id)

  return data
}

export default removeCharacter
