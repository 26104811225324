import { Tabs } from "@mantine/core"
import React from "react"
import Output from "./component/Output"
import Input from "./component/input/Input"
import { TextState } from "../../../../types"

type Properties = {
  tab: string
  name: string
  suggestions: string[]
  generate: (state: TextState) => Promise<void>
}

const GeneratedContentPanel = ({
  tab,
  generate,
  name,
  suggestions,
}: Properties) => {
  return (
    <Tabs.Panel h={"100%"} value={tab}>
      <Output name={name} />
      <Input name={name} generate={generate} suggestions={suggestions} />
    </Tabs.Panel>
  )
}

export default GeneratedContentPanel
