import React from "react"
import { createBrowserRouter } from "react-router-dom"
import GeneralErrorPage from "./errors/GeneralErrorPage"
import CreateCharacterPage from "./routes/character/CreateCharacterPage"
import Shell from "./shell/Shell"
import ExplorePage from "./routes/explore/ExplorePage"
import characterCreateLoader from "./loaders/characterCreateLoader"
import PrivacyPolicyPage from "./routes/privacyPolicy/PrivacyPolicyPage"
// import CreateSessionPage from "./routes/session/createSessionPage/CreateSessionPage"
// import PlaySessionPage from "./routes/session/playSessionPage/PlaySessionPage"
// import sessionCreateLoader from "./loaders/sessionCreateLoader"
// import sessionLoader from "./loaders/sessionLoader"
// import ConcludedSessionPage from "./routes/session/concludedSessionPage/ConcludedSessionPage"

const router = createBrowserRouter([
  {
    path: "/",
    element: <Shell />,
    errorElement: <GeneralErrorPage />,
    children: [
      {
        path: "",
        element: <ExplorePage />,
      },
      {
        path: "character/editor/:id",
        element: <CreateCharacterPage />,
        loader: characterCreateLoader,
      },
      {
        path: "privacy-policy",
        element: <PrivacyPolicyPage />,
      },
      // {
      //   path: "session/create",
      //   element: <CreateSessionPage />,
      //   loader: sessionCreateLoader,
      // },
      // {
      //   path: "session/play/:id",
      //   element: <PlaySessionPage />,
      //   loader: sessionLoader,
      // },
      // {
      //   path: "session/concluded/:id",
      //   element: <ConcludedSessionPage />,
      //   loader: sessionLoader,
      // },
    ],
  },
])

export default router
