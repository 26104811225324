import React, { ReactNode } from "react"
import { useMantineTheme } from "@mantine/core"
import styled from "styled-components"

interface CornerCircleProps {
  children?: ReactNode
  top?: number
  right?: number
  left?: number
  bottom?: number
}

const StyledCornerCircle = styled.div<CornerCircleProps>`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: ${(props) => props.top}px;
  right: ${(props) => props.right}px;
  left: ${(props) => props.left}px;
  bottom: ${(props) => props.bottom}px;
  border-radius: 50%;
  height: 24px;
  width: 24px;
  z-index: 1;
  color: #f1daca;
  opacity: 0.87;
`

const CornerCircle = ({
  children,
  top,
  right,
  left,
  bottom,
}: CornerCircleProps) => {
  const theme = useMantineTheme()

  return (
    <StyledCornerCircle
      top={top}
      right={right}
      left={left}
      bottom={bottom}
      theme={theme}
    >
      {children}
    </StyledCornerCircle>
  )
}

export default CornerCircle
