import React, { useContext, useEffect, useState } from "react"
import useTokenBalance from "../../../../../api/tokens/useTokenBalance"
import { UserContext } from "../../../../../global/user"
import { useHash } from "@mantine/hooks"
import { FormProvider, useForm } from "react-hook-form"
import openGetMoreTokensModal from "../../../../shell/components/getMoreTokensModal/openGetMoreTokensModal"
import ai from "../../../../../api/ai"
import { notifications } from "@mantine/notifications"
import { Flex, Text, Title } from "@mantine/core"
import { logSummonCharacterButtonClicked } from "../../../../../firebase/analytics"
import { Character } from "../../../../../types"
import findSummoningCharacter from "../../../../../api/character/findSummoningCharacter"
import { doc, onSnapshot } from "firebase/firestore"
import { firestore } from "../../../../../firebase"
import HeroBanner from "./components/HeroBanner"
import { useTranslation } from "react-i18next"
import SummonButton from "./components/SummonButton"
import PromptInput, { randomSuggestion } from "./components/PromptInput"
import styled from "styled-components"

const SummonCharacter = ({
  refetchAllCharacters,
}: {
  refetchAllCharacters: () => void
}) => {
  const { t } = useTranslation("explore")
  const { total } = useTokenBalance()

  const hasNoTokens = total !== undefined && total < 10

  const user = useContext(UserContext)

  const isAuthenticated = Boolean(user)

  const [summoningCharacter, setSummoningCharacter] = useState<Character>()

  const methods = useForm<{ prompt: string }>({
    defaultValues: {
      prompt: randomSuggestion(),
    },
  })

  const summonCharacter = async (data: { prompt: string }) => {
    logSummonCharacterButtonClicked(data.prompt)

    if (hasNoTokens || !isAuthenticated) {
      openGetMoreTokensModal()
    } else {
      try {
        const summoningCharacter = await ai.createQuickCharacter(data.prompt)

        setSummoningCharacter(summoningCharacter)

        notifications.show({
          title: t("summonCharacter.toasts.commenceSuccess.title"),
          message: t("summonCharacter.toasts.commenceSuccess.message"),
          color: "green",
        })
      } catch (error) {
        notifications.show({
          title: t("summonCharacter.toasts.generalError.title"),
          message: t("summonCharacter.toasts.generalError.message"),
          color: "red",
        })
      }
    }
  }

  const [, setHash] = useHash()

  useEffect(() => {
    if (user) {
      const fetchSummoningCharacter = async () => {
        const character = await findSummoningCharacter(user.uid)

        setSummoningCharacter(character)

        if (character?.prompt) methods.setValue("prompt", character.prompt)
      }
      fetchSummoningCharacter()
    }
  }, [user])

  useEffect(() => {
    if (!summoningCharacter || !summoningCharacter.id) return
    const unsubscribe = onSnapshot(
      doc(firestore, "characters", summoningCharacter.id),
      (doc) => {
        const quickCharacter = { id: doc?.id, ...doc.data() } as Character

        if (quickCharacter.status === "summoning") {
          try {
            setHash(`character${quickCharacter?.id}`)

            setSummoningCharacter(undefined)
          } catch (error) {
            notifications.show({
              title: t("summonCharacter.toasts.generalError.title"),
              message: t("summonCharacter.toasts.generalError.message"),
              color: "red",
            })
          }
        }

        if (quickCharacter.status === "published") {
          refetchAllCharacters()
          methods.setValue("prompt", randomSuggestion())
        }
      }
    )

    return () => {
      unsubscribe()
    }
  }, [summoningCharacter])

  const isSummoning = Boolean(
    summoningCharacter?.status === "summoning" || methods.formState.isSubmitting
  )

  return (
    <HeroBanner>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(summonCharacter)}>
          <BlackCard>
            <Title order={2}>{t("summonCharacter.title")}</Title>
            <Text>{t("summonCharacter.description")}</Text>

            <PromptInput disabled={isSummoning} />
            <SummonButton disabled={isSummoning} />
          </BlackCard>
        </form>
      </FormProvider>
    </HeroBanner>
  )
}

const BlackCard = styled(Flex)`
  background-color: black;
  border-radius: 16px;
  padding: 16px;
  margin: 16px;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
`

export default SummonCharacter
