import React from "react"
import { useTranslation } from "react-i18next"
import Selector from "../../../../../../ui/Selector"
import attributes from "../../attributes"
import { useLoaderData } from "react-router-dom"
import { AttributeOptions } from "../../../../../../types"

const ClassSelect = () => {
  const { t } = useTranslation("generateCharacter")
  const {
    attributeOptions: { classes },
  } = useLoaderData() as {
    attributeOptions: AttributeOptions
  }

  return (
    <Selector
      name={attributes.class}
      label={t("selector.class.label")}
      options={classes}
    />
  )
}

export default ClassSelect
