import { httpsCallable } from "firebase/functions"
import { functions } from "../../firebase"
import endpoints from "../endpoints"
import { logCreatePaymenSessionEvent } from "../../firebase/analytics"

const createPaymentSession = async (): Promise<string> => {
	logCreatePaymenSessionEvent()

	const { data: url } = await httpsCallable(
		functions,
		endpoints.CREATE_PAYMENT_SESSION
	)({ returnUrl: window.location.href })

	return url as string
}

export default createPaymentSession
