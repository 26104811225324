import { Flex, Loader, Text } from "@mantine/core"
import React from "react"

const Paragraphs = ({ text }: { text: string | undefined }) => {
  return (
    <>
      {text ? (
        text?.split("\n\n").map((paragraph, i) => (
          <Text mb={12} key={i}>
            {paragraph.replace(/['"]+/g, "")}
          </Text>
        ))
      ) : (
        <Flex w="100%" h={"100%"} align={"center"} justify={"center"}>
          <Loader size={"sm"} color="gray" />
        </Flex>
      )}
    </>
  )
}

export default Paragraphs
