import React from "react"
import { Group } from "@mantine/core"
import { useFormContext } from "react-hook-form"
import GenerateButton from "../../../../../../ui/GenerateButton"
import SingleLineInput from "../../../../../../ui/SingleLineInput"
import ai from "../../../../../../api/ai"
import attributes from "../../attributes"
import { CharacterCreateSession } from "../../../../../../types"
import { useTranslation } from "react-i18next"
import { functions } from "../../../../../../firebase"

const NameInput = () => {
  const { t } = useTranslation("generateCharacter")
  const { watch, setValue } = useFormContext()

  const generate = async () => {
    const name = await ai.generateName(functions)(
      watch() as CharacterCreateSession
    )
    setValue(attributes.name, name, { shouldDirty: true })
  }

  return (
    <Group spacing="sm" align="end" noWrap>
      <SingleLineInput
        name={attributes.name}
        placeholder={t("input.name.placeholder") as string}
        label={t("input.name.label")}
      />
      <GenerateButton cost={1} generate={generate} />
    </Group>
  )
}

export default NameInput
