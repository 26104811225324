import { Button, Text } from "@mantine/core"
import React, { useContext } from "react"
import { useTranslation } from "react-i18next"
import { auth } from "../../../../../../firebase"
import { UserContext } from "../../../../../../global/user"

const SignOut = () => {
  const { t } = useTranslation("profile")
  const user = useContext(UserContext)

  const signOut = async () => {
    try {
      if (user) localStorage.setItem("signedOutUser", user.uid)
      await auth.signOut()
      localStorage.removeItem("user")
      window.location.reload()
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Button variant="outline" color="gray" size="sm" onClick={signOut}>
      <Text>{t("signOut")}</Text>
    </Button>
  )
}

export default SignOut
