import { useEffect, useRef, useState } from "react"
import { Character } from "../../../types"
import { useInfiniteQuery } from "@tanstack/react-query"

const usePaginatedInfinityCharacters = (
  direction: "horizontal" | "vertical",
  queryKey: string,
  apiCall: (
    query: string | null,
    cursor: number,
    startCount: number,
    loadCount: number
  ) => Promise<Character[]>
) => {
  const isMobile = window.innerWidth < 768

  const count = isMobile ? 4 : 15

  const [shouldFetchMore, setShouldFetchMore] = useState(false)

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    refetch,
  } = useInfiniteQuery({
    queryKey: [queryKey],
    queryFn: async ({ pageParam }) => apiCall(null, pageParam, count, count),
    initialPageParam: 0,
    getNextPageParam: (lastPage, pages) => {
      return lastPage.length > 0 ? pages.flat().length : undefined
    },
  })

  const characters = data?.pages.flat() || []

  useEffect(() => {
    if (shouldFetchMore && hasNextPage && !isFetching && !isFetchingNextPage) {
      fetchNextPage()
      setShouldFetchMore(false)
    }
  }, [shouldFetchMore, isFetching, isFetchingNextPage])

  const lastId = useRef<string | undefined>(
    characters[characters.length - 1]?.id
  )

  useEffect(() => {
    lastId.current = characters[characters.length - 1]?.id
  }, [characters])

  const loadMoreIfNecessary = () => {
    if (cursorIsClose(direction, isMobile, lastId.current))
      setShouldFetchMore(true)

    return
  }

  return {
    characters: data?.pages.flat() || [],
    loading: isFetching || isFetchingNextPage,
    loadMoreIfNecessary,
    refetch: () => {
      refetch()
    },
  }
}

const cursorIsClose = (
  direction: "horizontal" | "vertical",
  isMobile: boolean,
  id?: string
) => {
  if (!id) return false
  const element = document.getElementById(id)
  if (!element) return false
  const rect = element.getBoundingClientRect()

  const distanceToCursor =
    direction === "vertical"
      ? rect.top - window.innerHeight
      : rect.left - window.innerWidth

  const threshold = isMobile ? 200 : 450

  return distanceToCursor < threshold
}

export default usePaginatedInfinityCharacters
