import { Flex } from "@mantine/core"
import styled from "styled-components"

const Block = styled(Flex)`
	width: 100vw;
	height: 100%;
	display: flex;
	background-color: transparent;
	position: absolute;
	top: 0;
	z-index: 3;
	backdrop-filter: blur(5px);
	align-items: center;
	justify-content: center;
	overflow: hidden;
	padding: 16px;
`

export default Block
