import React from "react"
import { useEffect, useState } from "react"
import { Character } from "../../../types"
import { useDisclosure, useHash } from "@mantine/hooks"
import { doc, getDoc, onSnapshot } from "firebase/firestore"
import { firestore } from "../../../firebase"
import CharacterModal from "./components/CharacterModal"

const CharacterViewModal = () => {
  const [character, setCharacter] = useState<Character | null>(null)

  const [opened, { open, close }] = useDisclosure(false)

  const [hash, setHash] = useHash()

  const characterId = hash.replace("#character", "")

  useEffect(() => {
    const fetchFullCharacter = async () => {
      const fetchedCharacter = (
        await getDoc(await doc(firestore, "characters", characterId))
      ).data() as Character
      if (fetchedCharacter)
        setCharacter({ ...fetchedCharacter, id: characterId })
    }

    if (characterId && hash.includes("#character")) {
      open()
      fetchFullCharacter()
    } else close()

    if (character && character.status === "summoning") {
      const unsubscribe = onSnapshot(
        doc(firestore, "characters", character.id),
        (doc) => {
          const quickCharacter = { id: doc?.id, ...doc.data() } as Character
          if (quickCharacter) {
            setCharacter(quickCharacter)
          }
        }
      )

      return () => {
        unsubscribe()
      }
    }
  }, [characterId, character?.status])

  return (
    <CharacterModal
      character={character}
      opened={opened}
      close={() => {
        close()
        setCharacter(null)
        setHash("all")
      }}
    />
  )
}

export default CharacterViewModal
