import React from "react"
import { Tabs } from "@mantine/core"
import GeneratedContentPanel from "./components/generatedContentPanel/GeneratedContentPanel"
import SettingsPanel from "./components/settingsPanel/SettingsPanel"
import { TextState } from "../../types"

const TABS = {
  SETTINGS: "SETTINGS",
  GENERATED_CONTENT: "GENERATED_CONTENT",
}

type Properties = {
  name: string
  generate: (state: TextState) => Promise<void>
  suggestions: string[]
}

const Generator = ({ name, generate, suggestions }: Properties) => {
  return (
    <Tabs variant="outline" h={"100%"} defaultValue={TABS.GENERATED_CONTENT}>
      <Tabs.List>
        <Tabs.Tab value={TABS.SETTINGS}>Settings</Tabs.Tab>
        <Tabs.Tab value={TABS.GENERATED_CONTENT}>Generated content</Tabs.Tab>
      </Tabs.List>
      <SettingsPanel name={name} tab={TABS.SETTINGS} />
      <GeneratedContentPanel
        name={name}
        tab={TABS.GENERATED_CONTENT}
        suggestions={suggestions}
        generate={generate}
      />
    </Tabs>
  )
}

export default Generator
