import React, { useContext } from "react"
import { ActionIcon, TextInput, Transition } from "@mantine/core"
import { TRANSITION_DURATION } from "../config"
import { IconBrandTelegram } from "@tabler/icons-react"
import UIContext from "../../../../../../context"
import { useFormContext } from "react-hook-form"

type Properties = {
  name: string
  active: boolean
  setActive: (state: boolean) => void
}

const Prompt = ({ name, active, setActive }: Properties) => {
  const { register } = useFormContext()
  return (
    <TextInput
      placeholder="What do you want to add/edit?"
      onFocus={() => setActive(true)}
      size="md"
      sx={{ borderRadius: 0 }}
      rightSection={<CtaButton active={active} setActive={setActive} />}
      {...register(`${name}.prompt`)}
    />
  )
}

type CtaButtonProperties = {
  active: boolean
  setActive: (state: boolean) => void
}

const CtaButton = ({ active, setActive }: CtaButtonProperties) => {
  const { isGenerating } = useContext(UIContext)
  return (
    <Transition
      mounted={!active}
      transition="fade"
      duration={TRANSITION_DURATION}
    >
      {(styles) => (
        <div style={styles}>
          <ActionIcon
            onClick={() => setActive(true)}
            loading={isGenerating}
            color="orange"
            variant="filled"
            sx={{
              borderRadius: "50%",
            }}
          >
            <IconBrandTelegram size={16} />
          </ActionIcon>
        </div>
      )}
    </Transition>
  )
}

export default Prompt
