import { functions } from "../../firebase"
import { httpsCallable } from "firebase/functions"
import { Character } from "../../types"

const browseCharacters = async (
  query: string | null,
  cursor: number,
  startCount: number,
  loadCount: number
) => {
  const { data: characters } = await httpsCallable(
    functions,
    "browse_characters"
  )({ query, limit: cursor ? loadCount : startCount, cursor })
  return characters as Character[]
}

export default browseCharacters
