import { Tabs } from "@mantine/core"
import React from "react"
import SignUpForm from "./SignUpForm"
import SignInForm from "./SignInForm"
import { useTranslation } from "react-i18next"

const AuthenticationTabs = () => {
	const { t } = useTranslation("profile")
	return (
		<Tabs defaultValue="signUp" w={"100%"}>
			<Tabs.List grow>
				<Tabs.Tab value="signUp">{t("signUp")}</Tabs.Tab>
				<Tabs.Tab value="signIn">{t("signIn")}</Tabs.Tab>
			</Tabs.List>
			<Tabs.Panel value="signUp">
				<SignUpForm />
			</Tabs.Panel>
			<Tabs.Panel value="signIn">
				<SignInForm />
			</Tabs.Panel>
		</Tabs>
	)
}

export default AuthenticationTabs
