import React, { useEffect } from "react"
import PublishedCharacters from "./PublishedCharacters"
import { Flex, Title } from "@mantine/core"
import CharacterViewModal from "./CharacterViewModal"
import { YourCharacters } from "./YourCharacters"
import SummonCharacter from "./components/summonCharacter/SummonCharacter"
import usePaginatedInfinityCharacters from "./usePaginatedInfinityCharacters"
import browseYourCharacters from "../../../api/character/browseYourCharacters"
import browseCharacters from "../../../api/character/browseCharacters"
import browseYourFavoriteCharacters from "../../../api/character/browseYourFavoriteCharacters"
import YourFavoriteCharacters from "./YourFavoriteCharacters"
import { ExplorePageContext } from "./context"
import browsePopularCharacters from "../../../api/character/browsePopularCharacters"
import PopularCharacters from "./PopularCharacters"

const POPULAR_CHARACTERS_FEATURE_FLAG = true
const CREATE_QUICK_CHARACTER_FEATURE_FLAG = true
const YOUR_CHARACTERS_FEATURE_FLAG = true
const YOUR_FAVORITE_CHARACTERS_FEATURE_FLAG = true

const ExplorePage = () => {
  const yours = usePaginatedInfinityCharacters(
    "horizontal",
    "your-characters",
    browseYourCharacters
  )

  const published = usePaginatedInfinityCharacters(
    "vertical",
    "published-characters",
    browseCharacters
  )

  const yourFavorites = usePaginatedInfinityCharacters(
    "horizontal",
    "your-favorite-characters",
    browseYourFavoriteCharacters
  )

  const popular = usePaginatedInfinityCharacters(
    "horizontal",
    "popular-characters",
    browsePopularCharacters
  )

  const refetchAllCharacters = () => {
    yours.refetch()
    yourFavorites.refetch()
    published.refetch()
    popular.refetch()
  }

  useEffect(() => {
    window.addEventListener("scroll", published.loadMoreIfNecessary)
    return () => {
      window.removeEventListener("scroll", published.loadMoreIfNecessary)
    }
  }, [])

  return (
    <ExplorePageContext.Provider
      value={{ refetchAllCharacters, yours, yourFavorites, published, popular }}
    >
      <Flex gap={16} direction={"column"}>
        {CREATE_QUICK_CHARACTER_FEATURE_FLAG && (
          <>
            <SummonCharacter refetchAllCharacters={refetchAllCharacters} />
          </>
        )}
        {POPULAR_CHARACTERS_FEATURE_FLAG && (
          <>
            <Title px={16} order={2}>
              Popular characters
            </Title>
            <PopularCharacters />
          </>
        )}
        {YOUR_CHARACTERS_FEATURE_FLAG && yours.characters.length > 0 && (
          <>
            <Title px={16} order={2}>
              Your characters
            </Title>
            <YourCharacters />
          </>
        )}
        {YOUR_FAVORITE_CHARACTERS_FEATURE_FLAG &&
          yourFavorites.characters.length > 0 && (
            <>
              <Title px={16} order={2}>
                Favorites
              </Title>
              <YourFavoriteCharacters />
            </>
          )}
        <Title px={16} order={2}>
          Explore the realm
        </Title>
        <PublishedCharacters />
        <CharacterViewModal />
      </Flex>
    </ExplorePageContext.Provider>
  )
}

export default ExplorePage
