import { httpsCallable } from "firebase/functions"
import { functions } from "../../firebase"
import endpoints from "../endpoints"
import {
  AttributeOptions,
  CharacterCreateSession,
  SettingOptions,
} from "../../types"

const characterCreateSession = async (id: string) => {
  const { data } = await httpsCallable<
    string,
    {
      session: CharacterCreateSession
      attributeOptions: AttributeOptions
      settingOptions: SettingOptions
    }
  >(
    functions,
    endpoints.CHARACTER_CREATE_SESSION
  )(id)

  return data as {
    session: CharacterCreateSession
    attributeOptions: AttributeOptions
    settingOptions: SettingOptions
  }
}

export default characterCreateSession
