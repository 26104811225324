import React from "react"
import { Button } from "@mantine/core"
import { useTranslation } from "react-i18next"

type Properties = {
  disabled?: boolean
  loading: boolean
}

const SaveSessionButton = ({ disabled, loading }: Properties) => {
  const { t } = useTranslation("general")
  return (
    <Button type="submit" disabled={disabled} loading={loading} fullWidth>
      {t("actions.save")}
    </Button>
  )
}

export default SaveSessionButton
