import React from "react"
import { useFormContext } from "react-hook-form"
import ai from "../../../../../../api/ai"
import attributes from "../../attributes"
import { CharacterCreateSession } from "../../../../../../types"
import { functions } from "../../../../../../firebase"
import Generator from "../../../../../../ui/generator/Generator"

const StoryInput = () => {
  const { watch, setValue } = useFormContext()

  const generateWithPrompt = async () => {
    const story = await ai.generateStory(functions)({
      session: watch() as CharacterCreateSession,
    })

    setValue(`${attributes.story}.value`, story, { shouldDirty: true })
  }

  return (
    <Generator
      name={attributes.story}
      generate={generateWithPrompt}
      suggestions={suggestions}
    />
  )
}

export default StoryInput

const suggestions = [
  "Raised in the shadow of a legendary hero and now seeking to establish a personal identity.",
  "Life was forever changed by the discovery of a magical artifact.",
  "Stumbled upon a forbidden text that opened new realms of power and possibility.",
  "Survived harsh city streets until a chance encounter offered a new path in life.",
  "Born into a nomadic culture with a longing for stability, yet unable to escape the call of the open road.",
  "Uncovered an ancient door deep within the earth, sparking a journey into the unknown.",
  "Falsely accused of a crime, and forced to become a fugitive.",
  "Compelled to seek holy relics after a mysterious encounter with a divine entity.",
  "On a quest to reclaim a stolen masterpiece.",
  "Born during a celestial event, feeling a strange connection to the cosmos and driven to understand this destiny.",
  "Had to escape from a noble court after a misunderstanding, now wandering and always in search of a laugh.",
  "Renounced a life of crime after a brush with death, now using old skills for noble purposes.",
  "Witnessed a supernatural event as a child, and has been haunted by it ever since.",
  "Always seen strange visions and is on a quest to understand their meaning.",
  "Left home after a prophecy foretold a great destiny.",
  "Found a map to a lost civilization and seeks to uncover its secrets.",
  "Discovered a strange amulet that seems to change with the phases of the moon.",
  "Was the sole survivor of a shipwreck and spent months alone at sea.",
  "Always felt a strong connection to animals and can communicate with them in an uncanny way.",
  "Inherited a mysterious key from a stranger and seeks to discover what it opens.",
  "Saved a powerful being and was granted a single wish.",
  "Has vivid dreams of a past life and seeks to understand their significance.",
  "Carries a cryptic message from a dying stranger and is on a journey to deliver it.",
  "Found a peculiar stone that pulses with an unseen energy.",
  "Has a rare ability to see into other realms and is on a quest to understand why.",
  "Inherited a haunted mansion and seeks to rid it of its curse.",
  "Has a twin with whom they share a mysterious bond.",
  "Escaped from a mystical labyrinth and carries its ever-changing map.",
  "Found a mysterious book that writes its own stories.",
  "Was entrusted with a sacred object and must keep it safe from dark forces.",
  "Has a recurring dream of a distant land and feels compelled to find it.",
  "Discovered a forgotten melody that has a strange effect on listeners.",
  "Carries a broken artifact and seeks the legendary craftsman who can repair it.",
  "Lost everything in a dragon's attack and seeks to rebuild.",
  "Made a deal with a trickster spirit and must now fulfill a complex bargain.",
  "Has a magical tattoo that moves and changes with their mood.",
  "Has a secret admirer who sends cryptic messages and gifts.",
  "Has a debt to a powerful being and works to repay it.",
  "Carries a letter of introduction to a legendary hero, but can't find them.",
  "Has a family heirloom that is rumored to hold great power.",
  "Escaped from an enchanted forest and seeks to free its inhabitants.",
]
