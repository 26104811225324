import { Character, CharacterCreateSession } from "../../../../types"

export const characterFromSession = (
  session: CharacterCreateSession
): Character => {
  return {
    id: session.characterId,
    sessionId: session.id,
    identifiers: {
      name: session.identifiers.name,
    },
    attributes: {
      age: session.attributes.age.value,
      gender: session.attributes.gender.value,
      class: session.attributes.class.value,
      background: session.attributes.background.value,
      race: session.attributes.race.value,
    },
    content: {
      story: session.content.story.value,
      personality: session.content.personality.value,
      appearance: {
        description: session.content.appearance.description.value,
        image: session.content.appearance.image.value,
      },
    },
  } as Character
}

export const partialCharacterFromSession = (
  session: CharacterCreateSession
): Character => {
  return {
    identifiers: {
      name: session.identifiers.name,
    },
    attributes: {
      gender: session.attributes.gender.value,
    },
    content: {
      appearance: {
        image: session.content.appearance.image.value,
      },
    },
  } as Character
}
