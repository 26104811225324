import React, { useContext } from "react"
import { UserContext } from "../../../global/user"
import { Drawer, Flex } from "@mantine/core"
import AuthenticationTabs from "../../authentication/components/AuthenticationTabs"
import Balance from "./components/balance/Balance"
import Account from "./components/account/Account"

const ProfileDrawer = ({
  opened,
  onClose,
}: {
  opened: boolean
  onClose: () => void
}) => {
  const user = useContext(UserContext)
  return (
    <Drawer position="right" opened={opened} onClose={onClose}>
      {!user || user.isAnonymous ? (
        <AuthenticationTabs />
      ) : (
        <Flex
          style={{
            flexDirection: "column",
            gap: 16,
          }}
        >
          <Balance />
          <Account />
        </Flex>
      )}
    </Drawer>
  )
}

export default ProfileDrawer
