import React, { useContext } from "react"
import { Character } from "../../../types"
import { Carousel } from "@mantine/carousel"
import CharacterCard, {
  CHARACTER_CARD_DIMENSION_WIDTH,
} from "../../../ui/characterCard/CharacterCard"
import { Button, Text } from "@mantine/core"
import { YourCharacterBadges } from "./components/Badges"
import { ExplorePageContext } from "./context"
import { logCharacterModalEditActionClicked } from "../../../firebase/analytics"
import { useNavigate } from "react-router-dom"

export const YourCharacters = () => {
  const {
    yours: { characters, loadMoreIfNecessary },
  } = useContext(ExplorePageContext)

  return (
    <Carousel
      align={"start"}
      slideGap={"xs"}
      p={16}
      slideSize={CHARACTER_CARD_DIMENSION_WIDTH}
      onSlideChange={loadMoreIfNecessary}
    >
      {characters.map((character) => (
        <YourCharacterCard key={character.id} character={character} />
      ))}
    </Carousel>
  )
}

const YourCharacterCard = ({ character }: { character: Character }) => {
  const navigate = useNavigate()

  return (
    <Carousel.Slide key={character.id || "new-character"}>
      <CharacterCard
        top={<YourCharacterBadges character={character} />}
        bottom={
          <Button
            variant="outline"
            fullWidth
            onClick={(event) => {
              event.stopPropagation()
              if (character) {
                logCharacterModalEditActionClicked(
                  character.id,
                  "your-character-card"
                )
                navigate(`character/editor/${character.sessionId}`)
              }
            }}
          >
            <Text>Edit character</Text>
          </Button>
        }
        character={character}
      />
    </Carousel.Slide>
  )
}
