import endpoints from "../endpoints"
import { httpsCallable } from "firebase/functions"
import { Character } from "../../types"
import { functions } from "../../firebase"

const unpublishCharacter = async (character: Character): Promise<Character> => {
  const { data } = await httpsCallable<string, Character>(
    functions,
    endpoints.UNPUBLISH_CHARACTER
  )(character.id)

  return data as Character
}

export default unpublishCharacter
