import { Badge } from "@mantine/core"
import React from "react"

const EmailVerifiedBadge = ({
	emailVerified,
}: {
	emailVerified: boolean | undefined
}) => {
	if (emailVerified)
		return (
			<Badge color={"green"} variant={"light"}>
				Verified
			</Badge>
		)
	else
		return (
			<Badge color={"red"} variant={"light"}>
				Unverified
			</Badge>
		)
}

export default EmailVerifiedBadge
