import { Flex, Tabs } from "@mantine/core"
import React from "react"
import { SettingOptions } from "../../../../types"
import { useLoaderData } from "react-router-dom"
import Selector from "../../../Selector"

type Properties = {
  tab: string
  name: string
}

const SettingsPanel = ({ tab, name }: Properties) => {
  return (
    <Tabs.Panel m={8} h={"100%"} value={tab}>
      <Settings name={name} />
    </Tabs.Panel>
  )
}

const Settings = ({ name }: { name: string }) => {
  const { settingOptions } = useLoaderData() as {
    settingOptions: SettingOptions
  }
  return (
    <Flex m={16} gap={8} direction={"column"}>
      {Object.entries(settingOptions[name]).map(([key, setting]) => (
        <Selector
          label={setting.title}
          key={key}
          name={`${name}.settings.${key}`}
          options={setting.options}
        />
      ))}
    </Flex>
  )
}

export default SettingsPanel
