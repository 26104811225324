import { Textarea } from "@mantine/core"
import React from "react"
import { useFormContext } from "react-hook-form"

type Properties = {
  name: string
}

const Output = ({ name }: Properties) => {
  const { register } = useFormContext()
  return (
    <Textarea
      minRows={15}
      placeholder="Generated content"
      {...register(`${name}.value`, { required: true })}
    />
  )
}

export default Output
