import React, { useContext } from "react"
import { Flex, Text } from "@mantine/core"
import CharacterCardLoading from "../../../ui/characterCard/CharacterCardLoading"
import { useMediaQuery } from "@mantine/hooks"
import CharacterCard from "../../../ui/characterCard/CharacterCard"
import { PublishedCharacterBadges } from "./components/Badges"
import { ExplorePageContext } from "./context"

const PublishedCharacters = () => {
  const {
    published: { characters, loading },
  } = useContext(ExplorePageContext)

  const isMobile = useMediaQuery("(max-width: 768px)")

  const count = isMobile ? 4 : 15

  return (
    <>
      {/* <SearchBar query={query || ""} /> */}
      <Flex gap={16} wrap={"wrap"} justify={"center"}>
        {characters &&
          characters.map((character) => (
            <CharacterCard
              top={<PublishedCharacterBadges character={character} />}
              key={character.id}
              character={character}
            />
          ))}
        {loading &&
          [...Array(count)].map((_, i) => <CharacterCardLoading key={i} />)}
        {!loading && characters.length === 0 && <NoCharactersFound />}
      </Flex>
    </>
  )
}

const NoCharactersFound = () => {
  return <Text m={16}>No characters found, try another search!</Text>
}

export default PublishedCharacters
