import React from "react"
import createPaymentSession from "../../../../../../api/payments/createPaymentSession"
import { Button } from "@mantine/core"
import { IconPlus } from "@tabler/icons-react"
import { logCreatePaymentSessionClicked } from "../../../../../../firebase/analytics"

const AddTokensAction = () => {
  const [loading, setLoading] = React.useState(false)

  const startCheckout = async () => {
    logCreatePaymentSessionClicked("profile-drawer")
    setLoading(true)
    const url = await createPaymentSession()
    window.location.href = url
    setLoading(false)
  }

  return (
    <Button
      loading={loading}
      variant="outline"
      onClick={startCheckout}
      leftIcon={<IconPlus />}
    >
      Get more
    </Button>
  )
}

export default AddTokensAction
