import React, { ReactNode } from "react"
import styled from "styled-components"

interface FrameProps {
  children: ReactNode
}

const StyledFrame = styled.div`
  border-radius: 12px;
  margin-top: 8px;
  display: flex;
  justify-content: center;
`

const Frame = ({ children }: FrameProps) => {
  return <StyledFrame>{children}</StyledFrame>
}

export default Frame
