import React from "react"
import { useTranslation } from "react-i18next"
import { useLoaderData } from "react-router-dom"
import Selector from "../../../../../../ui/Selector"
import attributes from "../../attributes"
import { AttributeOptions } from "../../../../../../types"

const BackgroundSelect = () => {
  const { t } = useTranslation("generateCharacter")
  const {
    attributeOptions: { backgrounds },
  } = useLoaderData() as {
    attributeOptions: AttributeOptions
  }

  return (
    <Selector
      name={attributes.background}
      label={t("selector.background.label")}
      options={backgrounds}
    />
  )
}

export default BackgroundSelect
