import React, { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { useForm } from "react-hook-form"
import {
  EmailAuthProvider,
  createUserWithEmailAndPassword,
  linkWithCredential,
  sendEmailVerification,
  updateProfile,
} from "firebase/auth"
import { Button, Flex, Input, Text } from "@mantine/core"
import { AuthenticationDetails } from "../../../types"
import { formatErrorMessage } from "./utils"
import { Link } from "react-router-dom"
import { IconExternalLink } from "@tabler/icons-react"
import { UserContext } from "../../../global/user"
import finalizeConvertedUser from "../../../api/user/finalizeConvertedUser"
import { auth } from "../../../firebase"

type Credentials = {
  email: string
  password: string
  displayName: string | null
}

const SignUpForm = ({ close }: { close?: () => void }) => {
  const { t } = useTranslation("profile")
  const [errors, setErrors] = useState("")

  const [loading, setLoading] = useState(false)

  const anonymousUser = useContext(UserContext)

  const { register, handleSubmit } = useForm<AuthenticationDetails>()

  const signUp = async ({ email, password, displayName }: Credentials) => {
    setLoading(true)
    try {
      if (anonymousUser) {
        await linkWithCredential(
          anonymousUser,
          EmailAuthProvider.credential(email, password)
        )

        await updateProfile(anonymousUser, { displayName })
        await sendEmailVerification(anonymousUser)

        await finalizeConvertedUser(anonymousUser)
      } else {
        await createUserWithEmailAndPassword(auth, email, password)
      }
    } catch (error) {
      console.log(error)
      setErrors((error as Error).message)
    }

    setLoading(false)

    close && close()
  }

  return (
    <div>
      <form onSubmit={handleSubmit(signUp)}>
        <Flex
          my={16}
          w={"100%"}
          direction={"column"}
          gap={16}
          align={"stretch"}
        >
          <Input
            placeholder="Username"
            type="text"
            {...register("displayName")}
          />
          <Input placeholder="Email" type="email" {...register("email")} />
          <Input
            placeholder="Password"
            type="password"
            {...register("password")}
          />
          {errors && <Text color="red">{formatErrorMessage(errors)}</Text>}

          <Flex align={"center"} wrap={"wrap"}>
            <Text>{t("agreeToTermsAndConditions")}</Text>
            <Link to="/privacy-policy" target="_blank">
              <Button
                compact
                color="gray"
                variant="subtle"
                rightIcon={<IconExternalLink size={20} />}
              >
                {t("termsAndConditions")}
              </Button>
            </Link>
          </Flex>
          <Button loading={loading} fullWidth type="submit">
            {t("signUp")}
          </Button>
        </Flex>
      </form>
    </div>
  )
}

export default SignUpForm
