import { Badge, Loader } from "@mantine/core"
import React from "react"

const AttributeBadge = ({
  attribute,
  dense,
}: {
  attribute: string
  dense?: boolean
}) => {
  if (!attribute) return <Loader color="gray" size={16} />

  if (dense)
    attribute = attribute.length > 8 ? attribute.slice(0, 6) + "..." : attribute

  return (
    <Badge
      variant="outline"
      sx={{ color: "#FFF7EC", borderColor: "#FFF7EC", opacity: 0.56 }}
      size="sm"
    >
      {attribute}
    </Badge>
  )
}

export default AttributeBadge
