import React from "react"
import HashAwareModal from "./HashAwareModal"
import { Flex, Text } from "@mantine/core"
import CreatePaymentSessionButton from "./CreatePaymentSessionButton"

const TokensPurchaseCanceledModal = () => {
	return (
		<HashAwareModal title="Payment Canceled 😔">
			<Flex direction="column" align="center" gap={16}>
				<Text style={{ marginBottom: 24 }}>
					Your payment was canceled. If you changed your mind, you can try again
					to purchase tokens for the AI-driven DnD character generator.
				</Text>
				<CreatePaymentSessionButton />
			</Flex>
		</HashAwareModal>
	)
}

export default TokensPurchaseCanceledModal
