import React from "react"
import { Notifications } from "@mantine/notifications"
import usePaymentResultNotification from "./components/usePaymentResultNotification"

const NotificationSystem = () => {
	usePaymentResultNotification()
	return (
		<>
			<Notifications />
		</>
	)
}

export default NotificationSystem
