import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useForm } from "react-hook-form"
import { AuthenticationDetails } from "../../../types"
import { auth } from "../../../firebase"
import {
	sendPasswordResetEmail,
	signInWithEmailAndPassword,
} from "firebase/auth"
import { formatErrorMessage } from "./utils"
import { Button, Flex, Input, Text } from "@mantine/core"
import { notifications } from "@mantine/notifications"
import { IconSend } from "@tabler/icons-react"

type Credentials = {
	email: string
	password: string
}

const SignInForm = () => {
	const { t } = useTranslation("profile")
	const [loading, setLoading] = useState(false)
	const [resetPasswordLoading, setResetPasswordLoading] = useState(false)
	const [errors, setErrors] = useState("")

	const { register, handleSubmit, watch } = useForm<AuthenticationDetails>()

	const signIn = async ({ email, password }: Credentials) => {
		setLoading(true)
		signInWithEmailAndPassword(auth, email, password)
			.catch((err) => {
				setErrors(err.message)
			})
			.finally(() => {
				setLoading(false)
			})
	}

	const resetPassword = () => {
		setResetPasswordLoading(true)
		sendPasswordResetEmail(auth, watch("email"))
			.then(() => {
				notifications.show({
					title: t("passwordResetTitle"),
					message: t("passwordResetMessage"),
					color: "green",
				})
			})
			.catch(() => {
				notifications.show({
					title: t("passwordResetFailedTitle"),
					message: t("passwordResetFailedMessage"),
					color: "red",
				})
			})
			.finally(() => {
				setResetPasswordLoading(false)
			})
	}

	return (
		<div>
			<form onSubmit={handleSubmit(signIn)}>
				<Flex
					my={16}
					w={"100%"}
					direction={"column"}
					gap={16}
					align={"stretch"}
				>
					<Input placeholder="Email" type="email" {...register("email")} />
					<Input
						placeholder="Password"
						type="password"
						{...register("password")}
					/>
					{errors && (
						<Text m={8} color="red">
							{formatErrorMessage(errors)}
						</Text>
					)}
					<Button
						loading={resetPasswordLoading}
						onClick={resetPassword}
						variant="subtle"
						compact
						color="gray"
						size="sm"
						sx={{ alignSelf: "flex-start" }}
						rightIcon={<IconSend size={16} />}
					>
						<Text>{t("forgotPassword")}</Text>
					</Button>
					<Button loading={loading} color={"orange"} type="submit" fullWidth>
						{t("signIn")}
					</Button>
				</Flex>
			</form>
		</div>
	)
}

export default SignInForm
