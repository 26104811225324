import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import en from "./en"

const initializeLocalization = () => {
	i18n.use(initReactI18next).init({
		resources: {
			en,
		},
		lng: "en",
		fallbackLng: "en",
		interpolation: {
			escapeValue: false,
		},
	})
}

export default initializeLocalization
