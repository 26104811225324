import React, { useContext } from "react"
import { Character } from "../../../../../types"
import { useMediaQuery } from "@mantine/hooks"
import { Badge, Button, Flex, Tabs, Text } from "@mantine/core"
import CharacterCard from "../../../../../ui/characterCard/CharacterCard"
import Attributes from "./components/Attributes"
import Content from "./components/Content"
import Conversation from "./components/Conversation"
import { UserContext } from "../../../../../global/user"
import { useNavigate } from "react-router-dom"
import { logCharacterModalEditActionClicked } from "../../../../../firebase/analytics"

const emptyCharacter: Character = {
  id: "",
  sessionId: "",

  identifiers: {
    name: "",
  },

  attributes: {
    age: "",
    gender: "",
    race: "",
    class: "",
    background: "",
  },

  content: {
    appearance: {
      description: "",
      image: {
        url: "",
      },
    },
    story: "",
    personality: "",
  },
}

const CharacterDisplay = ({ character }: { character?: Character | null }) => {
  const user = useContext(UserContext)
  const isMobile = useMediaQuery("(max-width: 600px)")

  const navigate = useNavigate()

  const isOwner = character?.user && user?.uid && character?.user === user?.uid

  if (!character) character = emptyCharacter

  return (
    <Flex direction={"column"} gap={16} style={{ borderRadius: "16px" }}>
      <Flex
        direction={isMobile ? "column" : "row"}
        align={isMobile ? "center" : "flex-start"}
        gap={24}
        p={8}
      >
        <div style={{ flex: 1 }}>
          <CharacterCard linkToImageUrl={true} character={character} />
          {isOwner ? (
            <Button
              mt={8}
              variant="outline"
              fullWidth
              onClick={() => {
                if (character) {
                  logCharacterModalEditActionClicked(
                    character.id,
                    "character-modal"
                  )
                  navigate(`character/editor/${character.sessionId}`)
                }
              }}
            >
              Edit character
            </Button>
          ) : undefined}
        </div>
        <Tabs w={"100%"} orientation="horizontal" defaultValue={"attributes"}>
          <Tabs.List grow>
            <Tabs.Tab w={"100%"} value="attributes">
              <Text>Get to know</Text>
            </Tabs.Tab>
            <Tabs.Tab
              disabled={!user || character.status === "summoning"}
              w={"100%"}
              value="chat"
            >
              <>
                <Text>Have a chat</Text>
                {!user && (
                  <Badge
                    pos={"absolute"}
                    color="green"
                    top={-0}
                    right={8}
                    size="xs"
                  >
                    requires login
                  </Badge>
                )}
              </>
            </Tabs.Tab>
          </Tabs.List>
          <Tabs.Panel value="attributes">
            <Attributes character={character} />
          </Tabs.Panel>
          <Tabs.Panel value="chat">
            {character.status !== "summoning" && (
              <Conversation character={character} />
            )}
          </Tabs.Panel>
        </Tabs>
      </Flex>
      <Content character={character} />
    </Flex>
  )
}

export default CharacterDisplay
