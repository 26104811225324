import { useCallback, useEffect, useState } from "react"

const useHash = () => {
	const [hash, setHash] = useState(() => window.location.hash)

	const hashChangeHandler = useCallback(() => {
		setHash(window.location.hash)
	}, [])

	useEffect(() => {
		window.addEventListener("hashchange", hashChangeHandler)
		return () => {
			window.removeEventListener("hashchange", hashChangeHandler)
		}
	}, [])

	const removeHash = () => {
		window.history.pushState(
			"",
			document.title,
			window.location.pathname + window.location.search
		)
		setHash(window.location.hash)
	}

	return { hash, removeHash }
}

export default useHash
