import React, { ReactNode, useEffect, useState } from "react"
import { Character, CharacterCreateSession } from "../../../../types"
import { FormProvider, useForm, useFormContext } from "react-hook-form"
import { Flex, Grid, Tabs, Text } from "@mantine/core"
import { useMediaQuery } from "@mantine/hooks"
import CharacterCard, {
  CHARACTER_CARD_DIMENSION_WIDTH,
} from "../../../../ui/characterCard/CharacterCard"
import {
  IconBook,
  IconBrain,
  IconPhoto,
  IconSettings,
} from "@tabler/icons-react"
import StoryInput from "./components/inputs/StoryInput"
import PersonalityInput from "./components/inputs/PersonalityInput"
import AppearenceInput from "./components/inputs/AppearenceInput"
import NameInput from "./components/inputs/NameInput"
import GenderSelect from "./components/selectors/GenderSelect"
import AgeSelect from "./components/selectors/AgeSelect"
import RaceSelect from "./components/selectors/RaceSelect"
import ClassSelect from "./components/selectors/ClassSelect"
import BackgroundSelect from "./components/selectors/BackgroundSelect"
import { firestore } from "../../../../firebase"
import { doc, updateDoc } from "firebase/firestore"
import debounce from "lodash.debounce"
import { partialCharacterFromSession } from "./utils"
import { useNavigate } from "react-router-dom"
import { notifications } from "@mantine/notifications"
import saveCharacter from "../../../../api/character/saveCharacter"
import SaveSessionButton from "./components/buttons/SaveSessionButton"

const saveSession = async (
  session: CharacterCreateSession,
  onSuccess: () => void
) => {
  await updateDoc(
    doc(firestore, "characterCreateSessions", session.id),
    session
  )
  onSuccess()
}

const debouncedAutoSave = debounce(saveSession, 2000)

const CharacterEditor = ({ session }: { session: CharacterCreateSession }) => {
  const methods = useForm<CharacterCreateSession>({
    defaultValues: session,
  })

  const saveSessionAndReset = () => methods.reset(methods.watch())

  useEffect(() => {
    if (methods.watch()) debouncedAutoSave(methods.watch(), saveSessionAndReset)
  }, [JSON.stringify(methods.watch())])

  const navigate = useNavigate()

  const saveCharacterDraft = async (session: CharacterCreateSession) => {
    try {
      const character = await saveCharacter(session)
      notifications.show({
        title: `${character.identifiers.name} successfully created`,
        message: "Your character has been saved",
        color: "green",
      })
      navigate("/")
    } catch (error) {
      notifications.show({
        title: "Error",
        message: "Something went wrong while save your character",
        color: "red",
      })
    }
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(saveCharacterDraft)}>
        <Grid m={8}>
          <CharacterInformationSection />
          <CharacterCardSection />
        </Grid>
      </form>
    </FormProvider>
  )
}

const CharacterCardSection = () => {
  const { watch, formState } = useFormContext<CharacterCreateSession>()
  const [character, setCharacter] = useState<Character | undefined>()

  useEffect(() => {
    setCharacter(partialCharacterFromSession(watch()))
  }, [JSON.stringify(partialCharacterFromSession(watch()))])

  return (
    <Grid.Col order={1} orderMd={2} xs={12} md={4}>
      <Flex direction="column" gap="xs" align={"center"} justify={"center"}>
        <CharacterCard edit={true} character={character} />
        <Flex
          direction="row"
          gap="xs"
          align={"center"}
          w={CHARACTER_CARD_DIMENSION_WIDTH}
        >
          <SaveSessionButton loading={formState.isSubmitting} />
        </Flex>
      </Flex>
    </Grid.Col>
  )
}

const CharacterInformationSection = () => {
  return (
    <Grid.Col order={2} orderMd={1} xs={12} md={8}>
      <Tabs defaultValue="base">
        <Tabs.List grow>
          <SectionTab value="base" icon={<IconSettings />} />
          <SectionTab value="appearance" icon={<IconPhoto />} />
          <SectionTab value="personality" icon={<IconBrain />} />
          <SectionTab value="story" icon={<IconBook />} />
        </Tabs.List>
        <Tabs.Panel value="base" pt="xs">
          <BaseSection />
        </Tabs.Panel>
        <Tabs.Panel value="appearance" pt="xs">
          <AppearenceSection />
        </Tabs.Panel>
        <Tabs.Panel value="personality" pt="xs">
          <PersonalitySection />
        </Tabs.Panel>
        <Tabs.Panel value="story" pt="xs">
          <StorySection />
        </Tabs.Panel>
      </Tabs>
    </Grid.Col>
  )
}

const StorySection = () => {
  return (
    <Flex gap="xs" direction="column">
      <StoryInput />
    </Flex>
  )
}

const PersonalitySection = () => {
  return (
    <Flex gap="xs" direction="column">
      <PersonalityInput />
    </Flex>
  )
}

const AppearenceSection = () => {
  return (
    <Flex gap="xs" direction="column">
      <AppearenceInput />
    </Flex>
  )
}

const BaseSection = () => {
  return (
    <Flex gap="xs" direction="column">
      <NameInput />
      <GenderSelect />
      <AgeSelect />
      <RaceSelect />
      <ClassSelect />
      <BackgroundSelect />
    </Flex>
  )
}

type SectionTabProperties = {
  icon: ReactNode
  value: string
}

const SectionTab = ({ icon, value }: SectionTabProperties) => {
  const isMobile = useMediaQuery("(max-width: 600px)")
  return (
    <Tabs.Tab value={value} icon={icon}>
      {isMobile ? "" : <Text transform="capitalize">{value}</Text>}
    </Tabs.Tab>
  )
}

export default CharacterEditor
