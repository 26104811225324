import React from "react"
import { MouseEventHandler, RefObject } from "react"
import { Card } from "@mantine/core"
import styled from "styled-components"
import {
  CHARACTER_CARD_DIMENSION_HEIGHT,
  CHARACTER_CARD_DIMENSION_WIDTH,
} from "./config"

const AnimatedShineCard = ({
  children,
  onClick,
  autoPlay = false,
}: {
  children: React.ReactNode
  onClick?: MouseEventHandler<HTMLDivElement>
  autoPlay?: boolean
}) => {
  const cardRef = React.useRef<HTMLDivElement>(null)

  return (
    <div
      onMouseMove={animateShineCardMouseOver(cardRef)}
      onClick={onClick}
      ref={cardRef}
    >
      {autoPlay ? (
        <StyledAnimatedShineCardAutoPlay>
          {children}
        </StyledAnimatedShineCardAutoPlay>
      ) : (
        <StyledAnimatedShineCard>{children}</StyledAnimatedShineCard>
      )}
    </div>
  )
}

export const animateShineCardMouseOver =
  (cardRef: RefObject<HTMLDivElement>): MouseEventHandler<HTMLDivElement> =>
  (e) => {
    if (!cardRef.current) return
    const x = e.pageX - cardRef.current.offsetLeft
    const y = e.pageY - cardRef.current.offsetTop

    cardRef.current.style.setProperty("--x", x + "px")
    cardRef.current.style.setProperty("--y", y + "px")
  }

const StyledAnimatedShineCard = styled(Card)`
  $white: #fff;
  $black: #000;
  border-radius: 12px;

  width: ${CHARACTER_CARD_DIMENSION_WIDTH};
  height: ${CHARACTER_CARD_DIMENSION_HEIGHT};

  box-shadow: 0px 6px 6px -1px rgba(0, 0, 0, 0.1),
    0px 2px 5px -1px rgba(0, 0, 0, 0.06);

  background-image: linear-gradient(
      rgba(36, 36, 36, 0.25),
      rgba(36, 36, 36, 0.5)
    ),
    url(https://firebasestorage.googleapis.com/v0/b/danion-development.appspot.com/o/general%2Fcard_frame.avif?alt=media&token=14821ab1-09c3-49af-8522-0df7c673753f);

  // Shine effect
  :hover:after {
    content: "";
    width: 250%;
    height: 250%;
    position: absolute;
    top: var(--y);
    left: var(--x);
    transform: translate(-50%, -50%);
    opacity: 0;
    background: radial-gradient(
      circle closest-side,
      rgba(255, 255, 255, 0.12),
      transparent
    );
    transition: opacity 0.5s ease-out;
  }

  :hover:after {
    opacity: 1;
  }
  background-color: #111111;
`

const StyledAnimatedShineCardAutoPlay = styled(Card)`
  $white: #fff;
  $black: #000;

  border-radius: 12px;

  background-image: linear-gradient(
      rgba(36, 36, 36, 0.25),
      rgba(36, 36, 36, 0.5)
    ),
    url(https://firebasestorage.googleapis.com/v0/b/danion-development.appspot.com/o/general%2Fcard_frame.avif?alt=media&token=14821ab1-09c3-49af-8522-0df7c673753f);

  width: ${CHARACTER_CARD_DIMENSION_WIDTH};
  height: ${CHARACTER_CARD_DIMENSION_HEIGHT};

  box-shadow: 0px 5px 6px -1px rgba(0, 0, 0, 0.1),
    0px 2px 5px -1px rgba(0, 0, 0, 0.06);

  background-color: #111111;
  pointer-events: none;
  filter: grayscale(1);
`

export default AnimatedShineCard
