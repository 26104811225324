import { Functions, httpsCallable } from "firebase/functions"
import { CharacterCreateSession } from "../../types"
import endpoints from "../endpoints"
import { notifications } from "@mantine/notifications"

type Properties = {
  session: CharacterCreateSession
}

const generateAppearenceDescription =
  (functions: Functions) =>
  async ({ session }: Properties): Promise<string> => {
    try {
      const { data: description } = await httpsCallable<Properties, string>(
        functions,
        endpoints.GENERATE_APPEARANCE_DESCRIPTION
      )({ session })
      return description
    } catch (error) {
      console.error(error)

      notifications.show({
        title: "Error",
        message: "Failed to generate appearence",
      })

      return session.content.appearance.description.value
    }
  }

export default generateAppearenceDescription
