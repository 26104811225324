const GENERATE_NAME = "generate_name"
const GENERATE_APPEARANCE = "generate_appearance"
const GENERATE_APPEARANCE_DESCRIPTION = "generate_appearance_description"
const GENERATE_APPEARANCE_IMAGE = "generate_appearance_image"
const GENERATE_APPEARANCE_EDIT = "generate_appearance_edit"
const GENERATE_STORY = "generate_story"
const GENERATE_PERSONALITY = "generate_personality"
const GENERATE_CHARACTER = "generate_character"
const CREATE_CHARACTER = "create_character"
const UPDATE_CHARACTER = "update_character"
const FETCH_ALL_FEATURES = "fetch_all_features"
const CREATE_PAYMENT_SESSION = "create_payment_session"
const NEXT_IMAGE = "next_image"
const PREVIOUS_IMAGE = "previous_image"
const CREATE_SESSION = "create_session"
const GET_SESSION = "get_session"
const PERFORM_CHARACTER_ACTION = "perform_character_action"
const PERFORM_ENTITY_ACTION = "perform_entity_action"
const ADVANCE_SESSION = "advance_session"
const CONCLUDE_SESSION = "conclude_session"
const REGISTER_ENTITY_FROM_STORY = "register_entity_from_story"

const CHARACTER_CREATE_SESSION = "character_create_session"
const SAVE_CHARACTER_CREATE_SESSION = "save_character_create_session"
const PUBLISH_CHARACTER = "publish_character"
const UNPUBLISH_CHARACTER = "unpublish_character"
const REMOVE_CHARACTER = "remove_character"

const SAVE_CHARACTER = "save_character"

const CREATE_QUICK_CHARACTER = "create_quick_character"

const BROWSE_YOUR_CHARACTERS = "browse_your_characters"
const BROWSE_YOUR_FAVORITE_CHARACTERS = "browse_your_favorite_characters"

const ADD_FAVORITE = "add_favorite"
const REMOVE_FAVORITE = "remove_favorite"
const GET_FAVORITE = "get_favorite"
const COUNT_FAVORITES = "count_favorites"

const BROWSE_POPULAR_CHARACTERS = "browse_popular_characters"

const GET_CONVERSATION = "get_conversation"
const REPLY_TO_CONVERSATION = "reply_to_conversation"

const FINALIZE_CONVERTED_USER = "finalize_converted_user"

const endpoints = {
  GENERATE_NAME,
  GENERATE_APPEARANCE,
  GENERATE_APPEARANCE_DESCRIPTION,
  GENERATE_APPEARANCE_IMAGE,
  GENERATE_APPEARANCE_EDIT,
  GENERATE_STORY,
  GENERATE_PERSONALITY,
  GENERATE_CHARACTER,
  CREATE_CHARACTER,
  UPDATE_CHARACTER,
  FETCH_ALL_FEATURES,
  CREATE_PAYMENT_SESSION,
  NEXT_IMAGE,
  PREVIOUS_IMAGE,
  CREATE_SESSION,
  GET_SESSION,
  PERFORM_CHARACTER_ACTION,
  PERFORM_ENTITY_ACTION,
  ADVANCE_SESSION,
  CONCLUDE_SESSION,
  REGISTER_ENTITY_FROM_STORY,
  CHARACTER_CREATE_SESSION,
  SAVE_CHARACTER_CREATE_SESSION,
  PUBLISH_CHARACTER,
  UNPUBLISH_CHARACTER,
  SAVE_CHARACTER,
  CREATE_QUICK_CHARACTER,
  BROWSE_YOUR_CHARACTERS,
  ADD_FAVORITE,
  REMOVE_FAVORITE,
  GET_FAVORITE,
  COUNT_FAVORITES,
  BROWSE_YOUR_FAVORITE_CHARACTERS,
  BROWSE_POPULAR_CHARACTERS,
  REMOVE_CHARACTER,
  GET_CONVERSATION,
  REPLY_TO_CONVERSATION,
  FINALIZE_CONVERTED_USER,
}

export default endpoints
