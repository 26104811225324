import { functions } from "../../firebase"
import { Character } from "../../types"
import endpoints from "../endpoints"
import { httpsCallable } from "firebase/functions"

type Properties = {
  prompt: string
}

const createQuickCharacter = async (prompt: string): Promise<Character> => {
  const { data: character } = await httpsCallable<Properties, Character>(
    functions,
    endpoints.CREATE_QUICK_CHARACTER,
    {
      timeout: 500000,
    }
  )({ prompt })

  return character
}

export default createQuickCharacter
