import React, { useContext } from "react"
import { Button } from "@mantine/core"
import { useState } from "react"
import UIContext from "./context"
import { useTranslation } from "react-i18next"
import { IconWand } from "@tabler/icons-react"
import useTokenBalance from "../api/tokens/useTokenBalance"
import openGetMoreTokensModal from "../app/shell/components/getMoreTokensModal/openGetMoreTokensModal"
import { UserContext } from "../global/user"
import { logGenerateButtonClickedEvent } from "../firebase/analytics"

type Properties = {
  generate: () => Promise<void> | void
  cost: number
}

const GenerateButton = ({ generate, cost }: Properties) => {
  const { t } = useTranslation("general")
  const { isGenerating, setIsGenerating } = React.useContext(UIContext)
  const [loading, setLoading] = useState(false)
  const { total } = useTokenBalance()
  const user = useContext(UserContext)

  const isAuthenticated = Boolean(user)

  const hasNoTokens = total !== undefined && total === 0

  const onClick = async () => {
    setIsGenerating(true)
    setLoading(true)
    logGenerateButtonClickedEvent()
    await generate()
    setLoading(false)
    setIsGenerating(false)
  }

  return (
    <Button
      type="button"
      onClick={() => {
        if (hasNoTokens || !isAuthenticated) openGetMoreTokensModal()
        else onClick()
      }}
      loading={loading}
      disabled={!loading && isGenerating}
      leftIcon={<IconWand />}
    >
      {t("actions.generate", { cost })}
    </Button>
  )
}

export default GenerateButton
