import React, { useContext } from "react"
import { Collapse, Flex, Button } from "@mantine/core"
import { TRANSITION_DURATION } from "../config"
import {
  IconArrowBackUp,
  IconBrandTelegram,
  IconRotateClockwise,
} from "@tabler/icons-react"
import UIContext from "../../../../../../context"
import useTokenBalance from "../../../../../../../api/tokens/useTokenBalance"
import { UserContext } from "../../../../../../../global/user"
import openGetMoreTokensModal from "../../../../../../../app/shell/components/getMoreTokensModal/openGetMoreTokensModal"
import { logSendButtonClickedEvent } from "../../../../../../../firebase/analytics"

type Properties = {
  active: boolean
  generate: () => Promise<void>
  revertToLatestPrompt: () => void
  resetPrompt: () => void
  hasHistory: boolean
  hasPrompt: boolean
}

const Controls = ({
  active,
  generate,
  revertToLatestPrompt,
  resetPrompt,
  hasHistory,
  hasPrompt,
}: Properties) => {
  return (
    <Collapse in={active} transitionDuration={TRANSITION_DURATION}>
      <Flex bg={"#25262b"} justify="space-between" align={"center"}>
        <Flex gap={8}>
          <UndoButton disabled={!hasPrompt} onClick={resetPrompt} />
          <RedoButton disabled={!hasHistory} onClick={revertToLatestPrompt} />
        </Flex>
        <SendButton generate={generate} />
      </Flex>
    </Collapse>
  )
}

const UndoButton = ({
  onClick,
  disabled,
}: {
  onClick: () => void
  disabled: boolean
}) => {
  return (
    <Button disabled={disabled} onClick={onClick} variant="subtle" size="md">
      <IconArrowBackUp size={16} />
    </Button>
  )
}

const RedoButton = ({
  onClick,
  disabled,
}: {
  onClick: () => void
  disabled: boolean
}) => {
  return (
    <Button disabled={disabled} onClick={onClick} variant="subtle" size="md">
      <IconRotateClockwise size={16} />
    </Button>
  )
}

const SendButton = ({ generate }: { generate: () => Promise<void> }) => {
  const { isGenerating, setIsGenerating } = React.useContext(UIContext)
  const [loading, setLoading] = React.useState(false)
  const { total } = useTokenBalance()
  const user = useContext(UserContext)

  const isAuthenticated = Boolean(user)

  const hasNoTokens = total !== undefined && total === 0

  const onClick = async () => {
    setIsGenerating(true)
    setLoading(true)
    logSendButtonClickedEvent()
    await generate()
    setLoading(false)
    setIsGenerating(false)
  }

  return (
    <Button
      onClick={() => {
        if (hasNoTokens || !isAuthenticated) openGetMoreTokensModal()
        else onClick()
      }}
      loading={loading}
      disabled={!loading && isGenerating}
      rightIcon={<IconBrandTelegram size={16} />}
      compact
      size="xs"
      m={8}
    >
      Send
    </Button>
  )
}

export default Controls
