import { Button, Flex, Text, Image } from "@mantine/core"
import React from "react"
import { useTranslation } from "react-i18next"

const SummonButton = ({ disabled }: { disabled: boolean }) => {
  const { t } = useTranslation("explore")

  return (
    <Button
      type="submit"
      variant="filled"
      loading={disabled}
      loaderProps={{ color: "transparent" }}
      rightIcon={
        <Flex align={"center"} justify={"center"} gap={6}>
          <Text>10</Text>
          <Image
            width={24}
            height={24}
            src={
              "https://firebasestorage.googleapis.com/v0/b/danion-development.appspot.com/o/general%2Fdanion-token.avif?alt=media&token=d4952c53-b112-4f73-8304-038a5f53779f"
            }
          />
        </Flex>
      }
      style={{
        alignSelf: "flex-end",
      }}
    >
      <Text>{t("summonCharacter.button")}</Text>
    </Button>
  )
}

export default SummonButton
