import { Carousel } from "@mantine/carousel"
import React, { useContext } from "react"
import CharacterCard, {
  CHARACTER_CARD_DIMENSION_WIDTH,
} from "../../../ui/characterCard/CharacterCard"
import { PublishedCharacterBadges } from "./components/Badges"
import { ExplorePageContext } from "./context"

const YourFavoriteCharacters = () => {
  const {
    yourFavorites: { characters, loadMoreIfNecessary },
  } = useContext(ExplorePageContext)

  return (
    <Carousel
      onSlideChange={loadMoreIfNecessary}
      align={"start"}
      slideGap={"xs"}
      p={16}
      slideSize={CHARACTER_CARD_DIMENSION_WIDTH}
    >
      {characters.map((character) => (
        <Carousel.Slide key={character.id}>
          <CharacterCard
            top={<PublishedCharacterBadges character={character} />}
            character={character}
          />
        </Carousel.Slide>
      ))}
    </Carousel>
  )
}

export default YourFavoriteCharacters
