import React from "react"
import { TextInput } from "@mantine/core"
import { useFormContext } from "react-hook-form"

type Properties = {
  name: string
  label: string
  placeholder?: string
}

const SingleLineInput = ({ name, label, placeholder }: Properties) => {
  const { register } = useFormContext()
  return (
    <TextInput
      id={`${name}-input`}
      placeholder={placeholder || label}
      label={label}
      size="sm"
      {...register(name, { required: true })}
    />
  )
}

export default SingleLineInput
