import React, { ReactNode, useEffect, useState } from "react"
import { Flex, Image, Loader, Text } from "@mantine/core"
import { Character } from "../../types"
import PlaceholderImage from "./components/PlaceholderImage"
import GenderIcon from "./components/GenderIcon"
import CornerCircle from "./components/CornerCircle"
import Frame from "./components/Frame"
import MaskingCanvas from "./components/MaskingCanvas"
import AnimatedShineCard from "./components/AnimatedShineCard"
import { doc, onSnapshot } from "firebase/firestore"
import { firestore } from "../../firebase"
import { useHash } from "@mantine/hooks"
import { CHARACTER_CARD_DIMENSION_WIDTH } from "./CharacterCard"
export {
  CHARACTER_CARD_DIMENSION_HEIGHT,
  CHARACTER_CARD_DIMENSION_WIDTH,
} from "./components/config"

type Properties = {
  character?: Character
  edit?: boolean
  top?: ReactNode
  bottom?: ReactNode
  linkToImageUrl?: boolean
}

const emptyCharacter: Character = {
  id: "",
  sessionId: "",

  identifiers: {
    name: "",
  },

  attributes: {
    age: "",
    gender: "",
    race: "",
    class: "",
    background: "",
  },

  content: {
    appearance: {
      description: "",
      image: {
        url: "",
      },
    },
    story: "",
    personality: "",
  },
}

const isEnriched = (character: Character) => {
  return !(
    character.editedAt &&
    (!character.enrichedAt || character.editedAt > character.enrichedAt)
  )
}

const isEmpty = (character: Character) => {
  return !character.id
}

const CharacterCard = ({
  character: initialCharacter,
  bottom,
  top,
  edit = false,
  linkToImageUrl = false,
}: Properties) => {
  const [enrichedCharacter, setEnrichedCharacter] = useState<Character | null>(
    null
  )

  const [, setHash] = useHash()

  const character = enrichedCharacter || initialCharacter || emptyCharacter

  const enriched = isEnriched(character)

  useEffect(() => {
    if (!edit && !enriched && !isEmpty(character)) {
      const unsubscribe = onSnapshot(
        doc(firestore, "characters", character.id),
        (doc) => {
          setEnrichedCharacter({ id: doc.id, ...doc.data() } as Character)
        }
      )

      return () => {
        unsubscribe()
      }
    }
  }, [enriched])

  const onClick = () => {
    if (character.id) {
      setHash(`character${character.id}`)
    }
  }

  return (
    <AnimatedShineCard
      onClick={enriched ? onClick : undefined}
      autoPlay={!enriched}
    >
      <Flex
        align={"center"}
        justify={"space-between"}
        direction={"column"}
        id={character.id}
        h={"100%"}
      >
        <Flex direction={"column"} justify={"space-between"}>
          <CornerCircle top={4} right={4}>
            <GenderIcon gender={character.attributes?.gender} />
          </CornerCircle>
          {top}
          <Frame>
            {character.content?.appearance?.image?.url ? (
              <>
                {edit && <MaskingCanvas />}
                <Image
                  onClick={
                    linkToImageUrl
                      ? () => {
                          if (character.content.appearance.image.url_1024x1024)
                            window.location.href =
                              character.content.appearance.image.url_1024x1024
                        }
                      : undefined
                  }
                  radius={"8px"}
                  height={256}
                  width={256}
                  src={
                    character.content.appearance?.image?.url_256x256 ||
                    character.content.appearance?.image?.url_512x512 ||
                    character.content.appearance?.image?.url
                  }
                  alt={character.identifiers?.name}
                  imageProps={{
                    loading: "lazy",
                  }}
                />
              </>
            ) : (
              <>
                {edit && <MaskingCanvas />}
                <PlaceholderImage />
              </>
            )}
            {!character.content?.appearance?.image &&
              character.status === "summoning" && (
                <Flex
                  align={"center"}
                  justify={"center"}
                  h={"100%"}
                  w={"100%"}
                  pos={"absolute"}
                >
                  <Loader color={"gray"} />
                </Flex>
              )}
          </Frame>
          <Flex
            direction={"column"}
            gap={4}
            mt={6}
            align={"center"}
            justify={"center"}
            maw={CHARACTER_CARD_DIMENSION_WIDTH}
          >
            <Text
              color="#FFF7EC"
              fw={"bolder"}
              truncate={true}
              maw={"calc(100% - 48px)"}
              opacity={0.86}
              size={"xl"}
            >
              {character.identifiers?.name || ""}
            </Text>
            <Text
              color="#FFF7EC"
              opacity={0.56}
              span
              maw={"calc(100% - 24px)"}
              fz="md"
              fs={"italic"}
              align="center"
              lineClamp={2}
            >
              {character.tagline || ""}
            </Text>
          </Flex>
        </Flex>
        {bottom}
      </Flex>
    </AnimatedShineCard>
  )
}

export default CharacterCard
