import React from "react"

import HashAwareModal from "./HashAwareModal"
import { Flex, Text } from "@mantine/core"

const TokensPurchaseSuccessModal = () => {
	return (
		<HashAwareModal title="Thank you for purchasing tokens! 🎉">
			<Flex direction="column" align="center" gap={16}>
				<Text style={{ marginBottom: 24 }}>
					You can now create more amazing characters and embark on epic
					adventures.
				</Text>
			</Flex>
		</HashAwareModal>
	)
}

export default TokensPurchaseSuccessModal
