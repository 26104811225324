import { Settings } from "luxon"
import React from "react"
import { createContext } from "react"

export type UIContextType = {
  isGenerating: boolean
  setIsGenerating: (isGenerating: boolean) => void
  settings: { [key: string]: Settings }
  updateSettings: (key: string, settings: Settings) => void
}

const UIContext = createContext<UIContextType>({
  isGenerating: false,
  setIsGenerating: () => null,
  settings: {},
  updateSettings: () => null,
})

export const UIProvider = ({ children }: { children: React.ReactNode }) => {
  const [isGenerating, setIsGenerating] = React.useState(false)
  const [settings, setSettings] = React.useState<{ [key: string]: Settings }>(
    {}
  )

  const updateSettings = (key: string, settings: Settings) => {
    setSettings((prev) => ({ ...prev, [key]: settings }))
  }

  return (
    <UIContext.Provider
      value={{
        isGenerating,
        setIsGenerating,
        settings,
        updateSettings,
      }}
    >
      {children}
    </UIContext.Provider>
  )
}

export default UIContext
