import React, { useContext, useEffect, useState } from "react"
import { Character } from "../../../../types"
import {
  ActionIcon,
  Flex,
  Input,
  Loader,
  Modal,
  Text,
  Tooltip,
} from "@mantine/core"
import {
  IconEye,
  IconEyeOff,
  IconHeart,
  IconHeartFilled,
  IconTrash,
} from "@tabler/icons-react"
import { UserContext } from "../../../../global/user"
import CharacterDisplay from "./characterDisplay/CharacterDisplay"
import favorites from "../../../../api/favorites"
import { useQuery } from "@tanstack/react-query"
import {
  logCharacterModalFavoriteActionClicked,
  logCharacterModalRemoveActionClicked,
} from "../../../../firebase/analytics"
import { ExplorePageContext } from "../context"
import removeCharacter from "../../../../api/character/removeCharacter"
import { useHash } from "@mantine/hooks"
import { notifications } from "@mantine/notifications"
import publishCharacter from "../../../../api/character/publishCharacter"
import unpublishCharacter from "../../../../api/character/unpublishCharacter"

type Properties = {
  character: Character | null
  opened: boolean
  close: () => void
}

const CharacterModal = ({ character, opened, close }: Properties) => {
  return (
    <>
      <Modal
        title={<CharacterTitle character={character} />}
        opened={opened}
        onClose={close}
        size="xl"
        p={8}
        closeOnClickOutside={true}
      >
        <Input sx={{ display: "none" }} data-autofocus />
        <CharacterDisplay character={character} />
      </Modal>
    </>
  )
}

const CharacterTitle = ({ character }: { character: Character | null }) => {
  const user = useContext(UserContext)

  const isOwner = character?.user && user?.uid && character?.user === user?.uid

  const hasUser = Boolean(user?.uid)

  if (!character)
    return (
      <Flex gap={16}>
        <Loader color="gray" />
      </Flex>
    )

  return (
    <Flex gap={16}>
      {hasUser && <FavoriteAction character={character} />}
      {isOwner && character.sessionId && <RemoveAction character={character} />}
      {isOwner && <PublishAction character={character} />}
      <Text>{character.identifiers?.name}</Text>
    </Flex>
  )
}

const RemoveAction = ({ character }: { character: Character }) => {
  const { refetchAllCharacters } = useContext(ExplorePageContext)
  const [, setHash] = useHash()

  const [loading, setLoading] = useState(false)

  const remove = async () => {
    try {
      logCharacterModalRemoveActionClicked(character.id)
      setLoading(true)
      await removeCharacter(character)
      refetchAllCharacters()
      setHash("all")
      notifications.show({
        title: "Character removed",
        message: "Character was successfully removed",
        color: "gray",
      })
    } catch (e) {
      console.error(e)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Tooltip color="dark" label="Remove character">
      <ActionIcon loading={loading} onClick={remove}>
        <IconTrash />
      </ActionIcon>
    </Tooltip>
  )
}

const FavoriteAction = ({ character }: { character: Character }) => {
  const { refetchAllCharacters } = useContext(ExplorePageContext)

  const { data: activeFavorite, refetch } = useQuery({
    queryKey: ["favorite", character.id],
    queryFn: () => favorites.getFavorite(character.id),
  })

  const [isFavorited, setIsFavorited] = useState(Boolean(activeFavorite))

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setIsFavorited(Boolean(activeFavorite))
  }, [activeFavorite])

  const addFavorite = async () => {
    setLoading(true)
    await favorites.addFavorite(character.id)
    setIsFavorited(true)
    refetch()
    refetchAllCharacters()
    setLoading(false)
  }

  const removeFavorite = async () => {
    setLoading(true)
    await favorites.removeFavorite(character.id)
    setIsFavorited(false)
    refetch()
    refetchAllCharacters()
    setLoading(false)
  }

  return (
    <Tooltip
      color="dark"
      label={isFavorited ? "Remove favorite" : "Save as favorite"}
    >
      <ActionIcon
        loading={loading}
        onClick={isFavorited ? removeFavorite : addFavorite}
        onClickCapture={() => {
          logCharacterModalFavoriteActionClicked(character.id)
        }}
      >
        {isFavorited ? <IconHeartFilled /> : <IconHeart />}
      </ActionIcon>
    </Tooltip>
  )
}

const PublishAction = ({
  character: initialCharacter,
}: {
  character: Character
}) => {
  const [character, setCharacter] = useState(initialCharacter)
  const { refetchAllCharacters } = useContext(ExplorePageContext)

  const [publishLoading, setPublishLoading] = useState(false)

  return character.status !== "published" ? (
    <Tooltip color="dark" label="Publish character">
      <ActionIcon
        loading={publishLoading}
        onClick={async (event) => {
          event.stopPropagation()
          setPublishLoading(true)
          setCharacter(await publishCharacter(character))
          setPublishLoading(false)
          refetchAllCharacters()
        }}
      >
        <IconEye />
      </ActionIcon>
    </Tooltip>
  ) : (
    <Tooltip color="dark" label="Unpublish character">
      <ActionIcon
        loading={publishLoading}
        onClick={async (event) => {
          event.stopPropagation()
          setPublishLoading(true)
          setCharacter(await unpublishCharacter(character))
          setPublishLoading(false)
          refetchAllCharacters()
        }}
      >
        <IconEyeOff />
      </ActionIcon>
    </Tooltip>
  )
}

export default CharacterModal
