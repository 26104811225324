import { collection, getDocs, query, where } from "firebase/firestore"
import { firestore } from "../../firebase"
import { Character } from "../../types"

const findSummoningCharacter = async (userId: string) => {
  const characterCollection = await collection(firestore, "characters")

  const characterQuery = await query(
    characterCollection,
    where("status", "==", "summoning"),
    where("user", "==", userId)
  )

  const charactersSnapshot = await getDocs(characterQuery)

  const characters = charactersSnapshot.docs.map(
    (character) =>
      ({
        id: character.id,
        ...character.data(),
      } as Character)
  )

  return characters[0]
}

export default findSummoningCharacter
