import generateName from "./generateName"
import generateStory from "./generateStory"
import generateAppearence from "./generateAppearence"
import generateAppearenceDescription from "./generateAppearenceDescription"
import generateAppearenceImage from "./generateAppearenceImage"
import generateAppearenceEdit from "./generateAppearenceEdit"
import generatePersonality from "./generatePersonality"
import createQuickCharacter from "./createQuickCharacter"
import getConversation from "./getConversation"
import replyToConversation from "./replyToConversation"

const ai = {
  generateName,
  generateStory,
  generateAppearence,
  generateAppearenceDescription,
  generateAppearenceImage,
  generateAppearenceEdit,
  generatePersonality,
  createQuickCharacter,
  getConversation,
  replyToConversation,
}

export default ai
