import { Functions, httpsCallable } from "firebase/functions"
import { Appearence, Character } from "../../types"
import endpoints from "../endpoints"
import { notifications } from "@mantine/notifications"

type Properties = {
  character: Character
  prompt: string | undefined
  settings:
    | { [index: string]: { value: string; description: string } }
    | undefined
}

const generateAppearence =
  (functions: Functions) =>
  async ({ character, prompt, settings }: Properties): Promise<Appearence> => {
    try {
      const {
        data: { description, image },
      } = await httpsCallable<Properties, Appearence>(
        functions,
        endpoints.GENERATE_APPEARANCE
      )({ character, prompt, settings })
      return { description, image }
    } catch (error) {
      console.error(error)

      notifications.show({
        title: "Error",
        message: "Failed to generate appearence",
      })

      return character.content.appearance
    }
  }

export default generateAppearence
