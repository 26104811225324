import React from "react"
import { useTranslation } from "react-i18next"

const GeneralErrorPage = () => {
	const { t } = useTranslation("general")
	return (
		<div>
			<h1>{t("errors.somethingWentWrong")}</h1>
		</div>
	)
}

export default GeneralErrorPage
