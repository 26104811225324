import { initializeApp } from "firebase/app"
import { connectFunctionsEmulator, getFunctions } from "firebase/functions"
import { connectAuthEmulator, getAuth } from "firebase/auth"
import { getRemoteConfig } from "firebase/remote-config"
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore"
import { getAnalytics } from "firebase/analytics"
import firebaseConfig from "./firebase_config.json"

const app = initializeApp(firebaseConfig)

export const functions = getFunctions(app)
export const auth = getAuth(app)
export const firestore = getFirestore(app)

// set ignoreUndefinedProperties to true

export const remoteConfig = getRemoteConfig(app)
export const analytics = getAnalytics(app)

if (process.env.REACT_APP_ENV === "local") {
  connectAuthEmulator(auth, "http://localhost:9099/auth")
  connectFirestoreEmulator(firestore, "localhost", 8080)
  connectFunctionsEmulator(functions, "localhost", 5001)
}

if (process.env.REACT_APP_ENV === "local-functions") {
  connectFunctionsEmulator(functions, "localhost", 5001)
}
