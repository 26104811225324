import {
  collection,
  doc,
  onSnapshot,
  query,
  QuerySnapshot,
  where,
} from "firebase/firestore"
import { useContext, useEffect, useState } from "react"
import { firestore } from "../../firebase"
import { UserContext } from "../../global/user"
import { Tokens } from "../../types"

const useTokenBalance = () => {
  const user = useContext(UserContext)
  const [total, setTotal] = useState<undefined | number>()
  const [transactions, setTransactions] = useState<Tokens[]>([])
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    if (user && user.uid) {
      setLoading(true)

      const tokensQuery = query(
        collection(firestore, "tokens"),
        where("user", "==", doc(firestore, "users", user.uid))
      )

      onSnapshot(tokensQuery, (tokens: QuerySnapshot) => {
        setLoading(true)
        const fetchedTransactions = tokens.docs.map((token) =>
          token.data()
        ) as Tokens[]
        setTotal(summarizeTokens(fetchedTransactions))
        setTransactions([...fetchedTransactions])
        setLoading(false)
      })
    }
  }, [user])

  return { total, transactions, loading }
}

const summarizeTokens = (tokens: Tokens[]) =>
  tokens.reduce((totalAmount, token) => token.amount + totalAmount, 0)

export default useTokenBalance
